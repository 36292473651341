import React, {useState} from "react";
import {useLanguage} from "../configuration/configuration";
import {getUserAvatar} from "../../actions/environment";
import {Link} from "react-router-dom";
import {useAuthorization} from "../../selectors";
import PageNotFound from "../not-found/page-not-found";
import useGlobalSearch, {GLOBAL_SEARCH} from "../hooks/use-global-search";
import {PENDING_STATUS, SUCCESS_STATUS} from "../../actions";
import SmoothScrollbar from "../scrollbars/smooth-scrollbar";
import {Close, Search} from "@material-ui/icons";
import {NavigationGroup, NavigationRow} from "./layout/nav-layout";

// helper component for navigation search menu
const NavigationSearchDropdown = ({className, onClose}) => {
    const [searchTerm, setSearchTerm] = useState(null);
    const {globalSearch, result, status} = useGlobalSearch();
    const {isAuthorized} = useAuthorization();
    const Lang = useLanguage();

    const handleSearchChange = (event) => {
        event.preventDefault();
        setSearchTerm(event.target.value);
    }

    const handleSearchSubmit = (event) => {
        if(event.key === 'Enter') {
            event.preventDefault();
            globalSearch(searchTerm, GLOBAL_SEARCH);
        }
    }

    const renderSearchResult = (resultList) => {
        if(!resultList || resultList.length === 0) return <div>{Lang.nav.search.noResults}</div>;
        return resultList.map((entry, index) => {
            const avatar = getUserAvatar(entry);
            const className = entry.type === 'SPACE' ? 'avatar-rectangular' : 'avatar-rounded';
            const name = entry.type === 'SPACE' ? entry.name : `${entry.name} (${entry.username})`;

            return <Link key={index} className="dropdown-item" to={entry.url}
                         onClick={onClose}>
                <div className="navigation-search-avatar">
                    <div className={className}><img src={avatar}/></div>
                </div>
                {name}
            </Link>
        });
    }

    if (!isAuthorized) return <PageNotFound/>
    if (status === PENDING_STATUS) return null;

    return <div className='navigation-search-container mx-2 mb-1'>
        <NavigationRow>
            <NavigationGroup>
                <h4 className='clr-popover-search'>{Lang.nav.search.text}</h4>
            </NavigationGroup>
            <NavigationGroup>
                <button className='btn btn-outline-primary m-0 p-1' onClick={onClose}>
                    <Close className='clr-popover-search'/>
                </button>
            </NavigationGroup>
        </NavigationRow>

        <div className='small text-bolder mb-2'>{Lang.nav.search.info}</div>
            <div className='box-aligned'>
                <input name="search"
                       className="form-control"
                       type="search"
                       placeholder={Lang.nav.search.placeholder}
                       onKeyPress={handleSearchSubmit}
                       onChange={handleSearchChange}/>
                <Search className='ml-2'/>
            </div>

        <SmoothScrollbar className="navigation-search-result mt-1" enableOverScroll={true}>
            {status === SUCCESS_STATUS && <div>{renderSearchResult(result)}</div>}
        </SmoothScrollbar>
    </div>
}

export default NavigationSearchDropdown;