const colours = ["#1abc9c", "#2ecc71", "#3498db", "#9b59b6", "#34495e", "#16a085", "#27ae60", "#2980b9",
    "#8e44ad", "#2c3e50", "#f1c40f", "#e67e22", "#e74c3c", "#95a5a6", "#f39c12", "#d35400", "#c0392b",
    "#bdc3c7", "#7f8c8d"];

export function generateAvatar(firstname, lastname, size = 160 ) {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const text = firstname && lastname ? `${firstname.charAt(0)}${lastname.charAt(0)}`: `${firstname.charAt(0)}`;
    const colourIndex = (firstname.charCodeAt(0) - 65) % 19;

    canvas.width = size;
    canvas.height = size;

    // Draw background
    context.fillStyle = colours[colourIndex];;
    context.fillRect(0, 0, canvas.width, canvas.height);

    // Draw text
    // context.font = "bold 40px Arial";
    context.font = "60px Arial";
    context.fillStyle = 'white';
    context.textAlign = "center";
    context.textBaseline = "middle";
    context.fillText(text, canvas.width / 2, canvas.height / 1.9);

    return canvas.toDataURL("image/png");
}
