import Scrollbar from 'smooth-scrollbar'
import OverscrollPlugin from 'smooth-scrollbar/plugins/overscroll'
import {useEffect, useRef} from "react";

// enable for bouncing effect
Scrollbar.use(OverscrollPlugin);

// https://github.com/idiotWu/smooth-scrollbar
// needs to implement onScrollStop callback for dynamic loading of content
const SmoothScrollbar = ({children, className, enableOverScroll = false, ...otherProps}) => {
    const targetRef = useRef(null);

    useEffect(() => {
        if (targetRef.current) {
            Scrollbar.init(targetRef.current, {
                plugins: {overscroll: enableOverScroll}
            });
        }
        return () => {
            Scrollbar.destroy(targetRef.current)
        };
    }, [])

    return <div className={`${className ? className : ''}`} ref={targetRef} {...otherProps}>
        {children}
    </div>
}

export default SmoothScrollbar;