import axios from "axios";
import {useState} from "react";
import {GENERIC_SPACE} from "../../actions/spaces";
import {
    getPeekMemberOfSpacesUrl,
    getPeekParentSpacesUrl,
    getPeekPublicSpacesUrl,
    getPublicUser
} from "../../actions/environment";
import {useAuthorization, useSiteConfiguration} from "../../selectors";
import {NONE_STATUS, PENDING_STATUS, SUCCESS_STATUS} from "../../actions";
import {authConfig} from "../../actions/local-storage";
import useErrorHandler from "./use-error-handler";

const usePeekGenericSpaces = () => {
    const [status, setStatus] = useState(NONE_STATUS);
    const [spaces, setSpaces] = useState(null);
    const {isAuthorized} = useAuthorization();
    const {handleError, error} = useErrorHandler(setStatus);

    const peekUserParentSpaces = username => {
        setStatus(PENDING_STATUS);
        axios.get(getPeekParentSpacesUrl(username, GENERIC_SPACE), authConfig())
        .then(response => {
            setSpaces(response.data);
            setStatus(SUCCESS_STATUS);
        })
        .catch(error => {
            console.log('peekParentSpaces', error.response);
            handleError(error, () => peekUserParentSpaces(username));
        })
    }

    // not authorized
    const peekPublicParentSpaces = () => {
        setStatus(PENDING_STATUS);
        axios.get(getPeekPublicSpacesUrl(getPublicUser(), GENERIC_SPACE), authConfig())
        .then(response => {
            setSpaces(response.data);
            setStatus(SUCCESS_STATUS);
        })
        .catch(error => {
            console.log('peekParentSpaces', error.response);
            handleError(error, () => peekPublicParentSpaces());
        })
    }

    // authorized
    const peekMemberOfSpaces = username => {
        setStatus(PENDING_STATUS);
        axios.get(getPeekMemberOfSpacesUrl(username, GENERIC_SPACE), authConfig())
        .then(response => {
            setSpaces(response.data);
            setStatus(SUCCESS_STATUS);
        })
        .catch(error => {
            console.log('peekMemberOfSpaces', error.response);
            handleError(error, () => peekMemberOfSpaces(username));
        })
    }

    // const peekGenericSpaces = (location) => {
    const peekGenericSpaces = (username, isParent) => {
        if (isAuthorized) {
            isParent ? peekUserParentSpaces(username) : peekMemberOfSpaces(username);
        } else {
            peekPublicParentSpaces();
        }
    }

    return {
        peekGenericSpaces: peekGenericSpaces,
        spaces: spaces,
        status: status}
}

export default usePeekGenericSpaces;