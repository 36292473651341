import {NONE_STATUS, PENDING_STATUS, SUCCESS_STATUS} from "../../actions";
import axios from "axios";
import {getUpdatePostVisibilityUrl, getUpdateSpaceUrl} from "../../actions/environment";
import {authConfig} from "../../actions/local-storage";
import {useState} from "react";
import useErrorHandler from "./use-error-handler";
import {useAuthorization} from "../../selectors";

/**
 * helper hook for creating and updating spaces synchronously (without redux)
 * data must contain all needed fields as in manage-space-form.js (s. SpacesFormData for details)
 */
const useUpdatePostVisibility = () => {
    const [status, setStatus] = useState(NONE_STATUS);
    const [post, setPost] = useState(null);
    const {principalUsername} = useAuthorization();
    const {handleError, error} = useErrorHandler(setStatus);

    // data is expected to have an access field as in {access: ACCESS_ONLY_ME}
    const updatePostVisibility = (postId, data, callback) => {
        setStatus(PENDING_STATUS);
        axios.post(getUpdatePostVisibilityUrl(principalUsername, postId), data, authConfig())
            .then(response => {
                setPost(response.data);
                setStatus(SUCCESS_STATUS);
                callback && callback(response.data);
            })
            .catch(error => {
                console.log('updatePostVisibility error', error);
                handleError(error, () => updatePostVisibility(postId, data, callback));
            })
    }

    return {
        updatePostVisibility: updatePostVisibility,
        post, status, error}
}

export default useUpdatePostVisibility;