import React, {useRef, useState} from 'react';
import {PLACEHOLDER} from "../../static";
import "plyr-react/plyr.css";
import {PlyrVideoPlayer} from "./plyr-video-player";
import {getImageServerMediumUrl, getStaticVideoUrl} from "../../actions/environment";

// default video player
const VideoPlayer = ({media}) => {
    const plyrRef = useRef();
    const [clicked, setClicked] = useState(false);

    const resolveThumbnail = () => {
        return media.thumbnail ? getImageServerMediumUrl(media.thumbnail) : null;
    }

    const renderVideo = () => {
        if (clicked) {
            const videoUrl = getStaticVideoUrl(media.url)
            return <PlyrVideoPlayer ref={plyrRef} autoplay={true} source={
                {type: "video", sources: [{src: videoUrl}]}
            }/>
        } else return (
            <a href='' onClick={event => {
                event.preventDefault();
                setClicked(true);
            }}>
                <img className="card-img" src={PLACEHOLDER} data-src={resolveThumbnail()}/>
            </a>
        );
    }

    return <div className='youtube-player'>
        {renderVideo()}
    </div>
}

export default VideoPlayer;