import axios from "axios";
import {useState} from "react";
import {GENERIC_SPACE} from "../../actions/spaces";
import {getPeekParentSpacesUrl} from "../../actions/environment";
import {useAuthorization, useSiteConfiguration} from "../../selectors";
import {NONE_STATUS, PENDING_STATUS, SUCCESS_STATUS} from "../../actions";
import {authConfig} from "../../actions/local-storage";
import useErrorHandler from "./use-error-handler";

const usePeekWidgets = () => {
    const [status, setStatus] = useState(NONE_STATUS);
    const [widgets, setWidgets] = useState(null);
    const {isAuthorized, isSuperUser, principalUsername} = useAuthorization();
    const {homePage, siteUsername} = useSiteConfiguration();
    const {handleError, error} = useErrorHandler(setStatus);

    const peekWidgets = username => {
        setStatus(PENDING_STATUS);
        axios.get(getPeekParentSpacesUrl(username, GENERIC_SPACE), authConfig())
        .then(response => {
            setWidgets(response.data);
            setStatus(SUCCESS_STATUS);
        })
        .catch(error => {
            console.log('peekParentSpaces', error.response);
            handleError(error, () => peekWidgets(username));
        })
    }

    return {
        peekWidgets: peekWidgets,
        widgets: widgets,
        status: status}
}

export default usePeekWidgets;