import {NOTIFY_GALLERY_CHANGE} from "../../actions";

// action.data expected something like {action: 'fetch', status: 'success', payload: page}
const GalleriesNotificationReducer = (state=null, action) => {
    switch (action.type)  {
        case NOTIFY_GALLERY_CHANGE:
            return {...state, gallery: action.data}
        default:
            return state;
    }
}

export default GalleriesNotificationReducer;