import DatePicker from "react-datepicker";
import React, {Fragment, useState} from "react";
import {FormRow, FormRowHeader} from "./manage-space-form";
import {useLanguage} from "../../configuration/configuration";
import {RoundedButton, WavesTooltipBounded} from "../../navigation/buttons/nav-buttons";
import {DeleteOutline, Description, DescriptionOutlined, Schedule, Today} from "@material-ui/icons";


// helper component
const DateTimeEditor = ({
                            title, date, from, until, description, name, onChangeDate, onChangeFrom, onChangeUntil,
                            onChangeDescription, onChangeName, onDelete, className
                        }) => {
    const [showDescription, setShowDescription] = useState(false);
    const [showName, setShowName] = useState(false);
    const Lang = useLanguage();

    const handleShowDescription = event => {
        event.preventDefault();
        setShowDescription(!showDescription);
    }

    const handleShowName = event => {
        event.preventDefault();
        setShowName(!showName);
    }

    return <Fragment>
        <FormRowHeader className={className}>
            <div className='col-md-5 py-0 px-1'>
                {title &&
                    <div className='form-align-title'>
                        <label className="form-check-label" htmlFor="startDateId">{title}</label>
                    </div>}
                <div className='standard-form-group'>
                    <span className='input-group-text'><Today/></span>
                    <DatePicker className="form-control"
                                selected={date}
                                id='startDateId'
                                onChange={date => onChangeDate(date)}
                                locale={Lang.locale}
                                placeholderText={Lang.updateSpace.startDate.text}
                        // dateFormat="d MMM, yyyy"
                                dateFormat="dd.MM.yyyy"
                                popperPlacement="top" fixedHeight required/>
                </div>
            </div>

            <div className='col-md-5 py-0 pl-1 pr-0'>
                <div className='standard-form-group'>
                    <span className='input-group-text'><Schedule/></span>
                    <DatePicker className="form-control" selected={from}
                                title=''
                                id='fromStartTimeId'
                                onChange={(date) => onChangeFrom(date)}
                                showTimeSelect
                                showTimeSelectOnly
                                timeFormat='HH:mm'
                                locale={Lang.locale}
                                timeIntervals={15}
                                placeholderText={Lang.updateSpace.date.from}
                                dateFormat="HH:mm"
                                timeCaption="Uhrzeit"
                                popperPlacement="top" required/>

                    <DatePicker className="form-control" selected={until}
                                id='untilStartTimeId'
                                onChange={(date) => onChangeUntil(date)}
                                showTimeSelect
                                showTimeSelectOnly
                                timeFormat='HH:mm'
                                locale={Lang.locale}
                                timeIntervals={15}
                                placeholderText={Lang.updateSpace.date.until}
                                dateFormat="HH:mm"
                                timeCaption="Uhrzeit"
                                autocomplete='off'
                                popperPlacement="top" required/>
                </div>
            </div>
            <div className='col-md-2 py-0 pl-2'>
                <div className='box-aligned justify-content-end'>
                    <RoundedButton title='Beschreibung eingeben' onClick={handleShowDescription}>
                        <DescriptionOutlined/>
                    </RoundedButton>

                    <RoundedButton title='Name eingeben' onClick={handleShowName}>
                        <DescriptionOutlined/>
                    </RoundedButton>
                    {onDelete &&
                        <RoundedButton title='Datum löschen' onClick={onDelete}>
                            <DeleteOutline/>
                        </RoundedButton>}
                </div>

            </div>
        </FormRowHeader>
        {showDescription && <FormRowHeader className='pl-1 pr-4'>
            <input className='form-control' type="text" name="description"
                   id='descriptionId'
                   placeholder='Kurze Datumsbeschreibung eingeben'
                   maxLength='48'
                   value={description}
                   onChange={onChangeDescription} required/>
        </FormRowHeader>}

        {showName && <FormRowHeader className='pl-1 pr-4'>
            <input className='form-control' type="text" name="name"
                   id='nameId'
                   placeholder='Name des Datums eingeben'
                   maxLength='48'
                   value={name}
                   onChange={onChangeName} required/>
        </FormRowHeader>}
    </Fragment>
}

export default DateTimeEditor;