import axios from "axios";
import {getAvatarUploadUrl} from "../../actions/environment";
import {authConfig} from "../../actions/local-storage";
import {
    asyncUpdateSurrogateAvatar,
    asyncUpdateUserAvatar,
    NONE_STATUS,
    PENDING_STATUS,
    SUCCESS_STATUS
} from "../../actions";
import {useState} from "react";
import {useDispatch} from "react-redux";
import useErrorHandler from "./use-error-handler";

const useUploadAvatar =() => {
    const [status, setStatus] = useState(NONE_STATUS);
    const {handleError, error} = useErrorHandler(setStatus);
    const dispatch = useDispatch();

    // @username: user for which the avatar is for
    // @isSurrogate: true - basically the superuser loading for somebody else
    // @data: filename
    const uploadAvatar = (username, isSurrogate, data) => {
        setStatus(PENDING_STATUS);
        axios.post(getAvatarUploadUrl(username), data, authConfig())
            .then(response => {
                if (isSurrogate) {
                    dispatch(asyncUpdateSurrogateAvatar(username, {path: response.data}, surrogate => {
                        setStatus(SUCCESS_STATUS)
                    }));
                } else {
                    dispatch(asyncUpdateUserAvatar(username, {path: response.data}, logindata => {
                        setStatus(SUCCESS_STATUS)
                    }));
                }
            })
            .catch(error => {
                console.log('Upload Avatar error', error);
                handleError(error, () => uploadAvatar(username, isSurrogate, data));
            });
    }

    return {uploadAvatar: uploadAvatar, status: status, error: error}
}
export default useUploadAvatar;