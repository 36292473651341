import {useEffect, useRef} from "react";
import {
    asyncFetchMemberOfSpaces,
    asyncFetchParentSpaces,
    asyncFetchWidgets,
    EVENT_SPACE,
    GENERIC_SPACE
} from "../../actions/spaces";
import {getPublicUser} from "../../actions/environment";
import {useAuthorization, useGenericSpaces, useSiteConfiguration} from "../../selectors";
import {useDispatch} from "react-redux";
import {NONE_STATUS, PENDING_STATUS, SUCCESS_STATUS} from "../../actions";

export const FETCH_ALL_SPACES = 'all_spaces';
export const FETCH_EVENTS_ONLY = 'events_only';
export const FETCH_SPACES_ONLY = 'generic_only'; // meaning generic spaces that are not events
export const FETCH_WIDGETS_ONLY = 'widgets_only';

/// ***************** @Deprecated
const useAsyncFetchGenericSpaces = (username, location, fetch = FETCH_ALL_SPACES) => {
    const status = useRef(
        {spaces: NONE_STATUS, events: NONE_STATUS, widgets: NONE_STATUS});
    const {isAuthorized, isSuperUser, principalUsername} = useAuthorization();
    const {spaces, events, widgets} = useGenericSpaces();
    const {homePage, siteUsername} = useSiteConfiguration();
    const dispatch = useDispatch();

    const fetchSpaces = fetchName => {
        status.current = {...status.current, spaces: PENDING_STATUS};
        if (fetchName.isParent) {
            dispatch(asyncFetchParentSpaces(fetchName.name, GENERIC_SPACE, null, data => {
                status.current = {...status.current, spaces: SUCCESS_STATUS};
            }));
        } else {
            dispatch(asyncFetchMemberOfSpaces(fetchName.name, GENERIC_SPACE, null, data => {
                status.current = {...status.current, spaces: SUCCESS_STATUS};
            }));
        }
    };

    const fetchEvents = fetchName => {
        status.current = {...status.current, events: PENDING_STATUS};
        dispatch(asyncFetchParentSpaces(fetchName.name, EVENT_SPACE, null, data => {
            status.current = {...status.current, events: SUCCESS_STATUS};
        }));
    };

    const fetchWidgets = fetchName => {
        status.current = {...status.current, widgets: PENDING_STATUS};
        dispatch(asyncFetchWidgets(fetchName, null, data => {
            status.current = {...status.current, widgets: SUCCESS_STATUS};
        }));
    };

    const resolveNameByUsername = () => {
        if (isAuthorized) {
            return username === siteUsername
                ? {name: siteUsername, isParent: true}
                : {name: principalUsername, isParent: false};
        }
        return {name: getPublicUser(), isParent: true}
    }

    const resolveFetchNameByLocation = () => {
        if(isAuthorized) {
            // superuser
            if(location.pathname === homePage) {
                return {name: siteUsername, isParent: true}
            // logged in user home space
            } else if(location.pathname === `/${principalUsername}/home`) {
                return {name: principalUsername, isParent: false}
            // smthg.else
            } else return {name: siteUsername, isParent: true}
        }
        return {name: getPublicUser(), isParent: true}
    }

    useEffect(() => {
        const fetchName = resolveFetchNameByLocation();
        if (fetch === FETCH_ALL_SPACES) {
            fetchSpaces(fetchName);
            fetchEvents(fetchName);
            fetchWidgets(principalUsername);
        } else if (fetch === FETCH_EVENTS_ONLY) {
            fetchEvents(fetchName);
        } else if (fetch === FETCH_SPACES_ONLY) {
            fetchSpaces(fetchName);
        } else if (fetch === FETCH_WIDGETS_ONLY) {
            fetchWidgets(principalUsername);
        }

    }, [username, siteUsername]);

    return {spaces: spaces, events: events, widgets: widgets, status: status.current}
}

export default useAsyncFetchGenericSpaces;