import React from "react";
import {useIsMobile, useLanguage} from "../configuration/configuration";
import {RoundedButton} from "../navigation/buttons/nav-buttons";
import {Add, DeleteForever} from "@material-ui/icons";
import {PLACEHOLDER} from "../../static";
import {getImageServerMediumUrl, getImageServerSmallUrl} from "../../actions/environment";
import YoutubePlayer from "../media-players/youtube-player";
import VimeoPlayer from "../media-players/vimeo-player";
import SoundcloudPlayer from "../media-players/soundcloud-player";
import VideoPlayer from "../media-players/video-player";

const TimelineMedia = ({media, index = 0,
                           showMoreMedia = 0,
                           first = false,
                           twin = false,
                           onClick,
                           onDelete,
                           isEditable= false,
                           ...otherProps
                       }) => {
    const Lang = useLanguage();
    const isMobile = useIsMobile();

    const handleOnDelete = event => {
        event.preventDefault();
        onDelete(media.id);
    }

    const renderDeleteIcon = () => {
        return <RoundedButton className="btn-primary timeline-gallery-icon"
                              title={Lang.button.deleteImage.text} onClick={handleOnDelete}>
            <DeleteForever className='clr-white' style={{fontSize: '1.2rem'}}/>
        </RoundedButton>
    }

    const resolvedClass = twin ? 'timeline-media-twin' : first ? 'timeline-media-first' : 'timeline-media-entry';

    const renderMedia = () => {
        switch (media.type) {
            case 'PICTURE': {
                return <div key={media.id} className={resolvedClass}
                            data-index={index}
                            onClick={onClick}>

                    <img src={PLACEHOLDER} data-src={isMobile
                             ? getImageServerSmallUrl(media.url)
                             : getImageServerMediumUrl(media.url)} alt=''/>

                    {isEditable && renderDeleteIcon()}

                    {showMoreMedia > 0 &&
                        <div className='timeline-gallery-more clr-white'>
                            <div className='centered-box m-0 p-0'>
                                <Add style={{fontSize: '2.5rem'}}/>
                                <span style={{fontSize: '2.0rem'}}>{showMoreMedia}</span>
                            </div>
                        </div>}
                </div>
            }
            case 'YOUTUBE':
                return <div className='timeline-media-youtube'>
                    <YoutubePlayer key={media.id} url={media.url} media={media}/>
                    {isEditable && renderDeleteIcon(media.id)}
                </div>

            case 'VIDEO':
                return <div className='timeline-media-youtube'>
                    <VideoPlayer media={media}/>
                    {isEditable && renderDeleteIcon(media.id)}
                </div>

            case 'VIMEO':
                return <VimeoPlayer key={media.id} url={media.url}/>

            case 'SOUNDCLOUD':
                return <SoundcloudPlayer key={media.id} url={media.url}/>

            default:
                return <div>Media not supported.. ({media.url})</div>
        }
    }

    return <div className={resolvedClass}  {...otherProps}>
        {renderMedia()}
    </div>

}
export default TimelineMedia;