// dynamically import date-fns locale
export const importDateFnsLocale = async (locale) => {
    return await import(
        /* webpackMode: "eager" */
        /* webpackPrefetch: true */
        /* webpackPreload: true */
        `date-fns/locale/${locale}`);
};

// dynamically import moment locale / currently using both moment an date-fns
// pending refactoring to date-fns only
export const importMomentLocale = async (locale) => {
    return await import(
        /* webpackMode: "eager" */
        /* webpackPrefetch: true */
        /* webpackPreload: true */
        `moment/locale/${locale}`);
};