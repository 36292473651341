import {useLocation} from "react-router-dom";

/**
 * example usage: const {foo, widget} = useQueryParams('foo', 'widget');
 */
export const useQueryParams = (...keys) => {
    const location = useLocation();
    let result = {query: new URLSearchParams(location.search)};
    keys.forEach((key) => {
        result = {...result, [key]: result.query.get(key)}
    })
    return result;
}