import {useLanguage} from "../configuration/configuration";
import React from "react";
import {TooltipLink} from "./buttons/nav-buttons";
import {NavigateNext} from "@material-ui/icons";
import {getImprintPageUrl, getPrivacyPolicyPageUrl, getTermsOfUsePageUrl} from "../../actions/environment";

// helper for navigation dropdown actions menu
const NavigationLegalDropdown = ({principalUsername}) => {
    const Lang = useLanguage();

    const getPage = (page) => {
        return `/${principalUsername}/page/${page}`
    }

    return <div className="dropdown-menu navbar-actions-dropdown">
        <TooltipLink className="dropdown-item" to={getPage(getImprintPageUrl())}>
            <span><NavigateNext className='ml--2'/>{ Lang.nav.header.imprint}</span>
        </TooltipLink>

        <TooltipLink className="dropdown-item" to={getPage(getPrivacyPolicyPageUrl())}>
            <span><NavigateNext className='ml--2'/>{Lang.nav.header.privacy}</span>
        </TooltipLink>

        <TooltipLink className='dropdown-item' to={getPage(getTermsOfUsePageUrl())}>
            <span><NavigateNext className='ml--2'/>{Lang.nav.header.terms}</span>
        </TooltipLink>
    </div>
}

export default NavigationLegalDropdown;