import useFetchGallery from "../../hooks/use-fetch-gallery";
import {useLanguage} from "../../configuration/configuration";
import React, {useEffect} from "react";
import {SUCCESS_STATUS} from "../../../actions";
import GenericToggler from "../../toggler/generic-toggler";
import {ArrowRight} from "@material-ui/icons";
import GalleryEntrySpace from "../../billboard-sidebar/lists/gallery-entry-space";
import {useAuthorization} from "../../../selectors";

const MobileGalleries = ({username, spaceId= null}) => {
    const {fetchGalleriesBySpace, fetchGallerySpaces, spaces: gallerySpaces, status} = useFetchGallery();
    const {isAuthorized} = useAuthorization();

    const Lang = useLanguage();

    useEffect(() => {
        if(spaceId && username) {
            isAuthorized && fetchGalleriesBySpace(username, spaceId);
        } else if(username) {
            username && fetchGallerySpaces(username);
        }
    }, [spaceId, username]);


    const renderGallerySpaces = () => {
        ;        const galleries = gallerySpaces
        .map(gallery => {
            const description = gallery.description ? gallery.description.substr(0,64) : null;

            return <li key={gallery.spaceId} className='list-unstyled mt-1 mb-2'>
                <GalleryEntrySpace space={gallery} size='medium' text={description}/>
            </li>
        })
        return <ul className='list-unstyled'>{galleries}</ul>
    }

    if(status !== SUCCESS_STATUS) return null;

    return <div className='generic-navigation-information'>
        <div>
            <GenericToggler icon={<ArrowRight/>} title={Lang.gallery.headline.moreGalleries} fullview={true}>
                <div>
                    {renderGallerySpaces()}
                </div>
            </GenericToggler>
        </div>
    </div>
}

export default MobileGalleries;