import usePeekEventSpaces from "../hooks/use-peek-event-spaces";
import React, {useEffect} from "react";
import {useAuthorization} from "../../selectors";
import {isISODateAfter, isSameDayFns, useIsMobile, useLanguage} from "../configuration/configuration";
import {EVENT_SPACE} from "../../actions/spaces";
import SidebarSimpleEntryEvent from "../billboard-sidebar/lists/sidebar-simple-entry-event";
import {ErrorBoundary} from "../error-boundary/ErrorBoundary";
import {SUCCESS_STATUS} from "../../actions";
import {Spinner} from "../util/spinner";
import {SwiperSlide} from "swiper/react";
import SwiperContainer from "../slider/swiper-container";

const EventsSlider = () => {
    const {peekEventSpaces, events, status: eventsStatus} = usePeekEventSpaces();
    const {superuser, principalUsername} = useAuthorization();
    const isMobile = useIsMobile();
    const Lang = useLanguage();

    useEffect(() => {
        // will get public events if not authorized
        peekEventSpaces(superuser, true);
    }, []);



    // if mobile render todays events additionally
    const renderUpcomingEvents = () => {
        // const pastMonth = getLastDayOfPastMonth();
        const today = new Date();

        const calendarEvents = events
        .filter(space => {
            if (space.calendarDate) {
                const isSameDay = isMobile && isSameDayFns(space.calendarDate.date, today);
                const isAfter = isISODateAfter(space.calendarDate.date, today);
                return (isAfter || isSameDay) && (space.type === EVENT_SPACE);
            } else return false;
        })
        .map(space => {
            return <SwiperSlide key={space.id}>
                <SidebarSimpleEntryEvent left peekspace={space}/>
            </SwiperSlide>
        })
        return calendarEvents.length > 0 ? calendarEvents :
            <div className='mb-2'>{Lang.nav.sidebar.events.noneUpcoming}</div>;
    }

    const renderFallBack = () => {
        return <div className='mb-2'>
            {Lang.nav.sidebar.spaces.noneUpcoming}
            <p>(Something went wrong)</p>
        </div>;
    }

    if (eventsStatus !== SUCCESS_STATUS) return <Spinner/>
    if (events.length === 0) return null;

    return <ErrorBoundary fallback={renderFallBack}>
        <div className='events-slider-container mb-3'>
            <SwiperContainer slidesPerView={1} pagination={false} autoplay={false}
                             breakpoints={{
                                 slidesPerView: 1,
                                 spaceBetween: 0,
                                 480: {slidesPerView: 2, spaceBetween: 10},
                                 640: {slidesPerView: 3, spaceBetween: 10},
                                 // 840: {slidesPerView: 4, spaceBetween: 10},
                             }}>
                {renderUpcomingEvents()}
            </SwiperContainer>
        </div>


    </ErrorBoundary>
}

export default EventsSlider;