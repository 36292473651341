// // ** USAGE EXPERIMENTAL **
// const myEfficientFn = debounce(function() {
//     // All the taxing stuff you do
//     console.log('RESIZE', window.innerWidth);
// }, 1000);
//
//
// // ** USAGE EXPERIMENTAL **
// window.addEventListener('resize', myEfficientFn);

// export function isMobile() {
//     return (/Mobile|Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i.test(navigator.userAgent));
// }

// recommended mobile detection by mozilla plus extensions regarding screen width
import UAParser from 'ua-parser-js'
const userAgentParser = new UAParser()

export function isMobileMozilla() {
    let hasTouchScreen;

    if(window.screen.width <= 480) {
        hasTouchScreen = true;          // might need to adjust this threshold

    } else if ("maxTouchPoints" in navigator) {
        hasTouchScreen = navigator.maxTouchPoints > 0;

    } else if ("msMaxTouchPoints" in navigator) {
        hasTouchScreen = navigator.msMaxTouchPoints > 0;

    } else {
        var mQ = window.matchMedia && matchMedia("(pointer:coarse)");
        if (mQ && mQ.media === "(pointer:coarse)") {
            hasTouchScreen = !!mQ.matches;

        } else if ('orientation' in window) {
            hasTouchScreen = true;          // deprecated, but good fallback

            // }else if(screen.width <= 480) {
            //     hasTouchScreen = true;          // might need to adjust this threshold

        } else {
            // Only as a last resort, fall back to user agent sniffing
            let UA = navigator.userAgent;
            hasTouchScreen = (
                /\b(Mobile|BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
                /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
            );
        }
    }
    return hasTouchScreen;
}

export function parseUserAgent(userAgent) {
    userAgentParser.setUA(userAgent);
    return {
        browser: userAgentParser.getBrowser().name,
        os: userAgentParser.getOS().name,
        device: userAgentParser.getDevice()
    }
}

