import React, {useEffect} from "react";
import {showForceVisibleImages} from "../../actions/image-handler";
import {debounce} from "../../actions";


// component participates in the native scroll events and calls a callback on scroll stop
const NativeScroller = ({children, forceVisible = false, onScrollStop, className}) => {

    useEffect(() => {
        forceVisible && showForceVisibleImages();
    })

    // using a more robust debouncer implementation
    const debouncedScrollHandler = debounce(function (event) {
        event.preventDefault();
        onScrollStop(event);
    }, 500);

    return (
        <div id="billboard-scroller" className={`billboard-native-scroller ${className ? className : ''}`}
             onScroll={debouncedScrollHandler}>
            {children}
        </div>
    )
}

export default NativeScroller;