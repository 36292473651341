import React from "react";
import {useLanguage} from "../../configuration/configuration";
import {Tooltip, WavesTooltipLink} from "../../navigation/buttons/nav-buttons";
import {DashboardOutlined, Widgets} from "@material-ui/icons";
import {ManageAccountsIcon} from "../../navigation/icons/nav-icons";
import {Popover} from "../../navigation/popover/popover";
import CreateGalleryPopover from "../../popover/create-gallery-popover";

const HomeActionNavigation = ({accessRoles, accessUrls}) => {
    const {isAuthorized, isOwner, isSuperUser} = accessRoles;
    const {editUrl} = accessUrls;
    const Lang = useLanguage();

    // close is a callback from popover component
    const handlePopoverRender = ({close}) => {
        return <div>
            <CreateGalleryPopover onClose={close}/>
        </div>
    }

    return <div className='generic-secondary-menu generic-navigation-buttons'>

        <Tooltip className='navigation-flat-button btn btn-overrides btn-sm btn-outline-light box-aligned'
                 title='Neue Bildergalerie erzeugen'>
            <Popover className='popover-status-container'
                     placement='bottom'
                     render={handlePopoverRender}>
                <button className='btn btn-sm rounded-button-regular rounded-button btn-light mr-1'>
                    <DashboardOutlined fontSize='small' className='font-1x'/>
                </button>
            </Popover>
            <span  className='hide-mobile-text'>Neue Bildergalerie</span>
        </Tooltip>

        {isAuthorized && (isOwner || isSuperUser) &&
            <WavesTooltipLink btn small flex
                              title={Lang.generic.nav.editUser.tooltip}
                              className='btn-outline-light generic-navigation-button'
                              to={editUrl}>
                <ManageAccountsIcon className="mr-1"/>
                <span
                    className='generic-navigation-text'>{Lang.generic.nav.editUser.text}</span>
            </WavesTooltipLink>}

        {isAuthorized && isSuperUser &&
            <WavesTooltipLink btn small flex
                              title={Lang.generic.nav.widget.tooltip}
                              className='btn-outline-light generic-navigation-button'
                              to={editUrl}>
                <Widgets fontSize='small' className="mr-1"/>
                <span
                    className='generic-navigation-text'> {Lang.generic.nav.widget.text}</span>
            </WavesTooltipLink>}
    </div>
};

export default HomeActionNavigation;