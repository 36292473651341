import axios from "axios";
import {useState} from "react";
import {GENERIC_SPACE} from "../../actions/spaces";
import {useAuthorization} from "../../selectors";
import {authConfig} from "../../actions/local-storage";
import {getMemberOfSpacesUrl, getShareablePeekSpacesUrl} from "../../actions/environment";
import useErrorHandler from "./use-error-handler";
import {NONE_STATUS, PENDING_STATUS, SUCCESS_STATUS} from "../../actions";

// authorized users only
const useFetchSpaces = () => {
    const [status, setStatus] = useState(NONE_STATUS);
    const [spaces, setSpaces] = useState([]);
    const {handleError, error} = useErrorHandler(setStatus);
    const {isAuthorized, principalUsername} = useAuthorization();

    const fetchMemberOfSpaces = () => {
        setStatus(PENDING_STATUS);
        axios.get(getMemberOfSpacesUrl(principalUsername, GENERIC_SPACE), authConfig())
        .then(response => {
            setSpaces(response.data);
            setStatus(SUCCESS_STATUS);
        })
        .catch(error => {
            handleError(error, () => fetchMemberOfSpaces());
        })
    }

    const fetchShareablePeekSpaces = () => {
        setStatus(PENDING_STATUS);
        axios.get(getShareablePeekSpacesUrl(principalUsername, GENERIC_SPACE), authConfig())
        .then(response => {
            setSpaces(response.data);
            setStatus(SUCCESS_STATUS);
        })
        .catch(error => {
            handleError(error, () => fetchShareablePeekSpaces());
        })
    }

    return {
        fetchMemberOfSpaces: fetchMemberOfSpaces,
        fetchShareablePeekSpaces: fetchShareablePeekSpaces,
        spaces: spaces,
        status: status,
        error: error
    }
}

export default useFetchSpaces;