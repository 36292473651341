/*
 port from official documentation flotation-ui site
 */
import React, {cloneElement, Fragment, isValidElement, useEffect, useState} from "react";
import {
    Placement, offset, flip, shift, autoUpdate, useFloating, useInteractions,
    useRole, useDismiss, useId, useClick, useFocusTrap, autoPlacement
} from "@floating-ui/react-dom-interactions";

/**
 * Placement = 'top', 'top-start', 'top-end', 'right', 'right-start',
 * 'right-end', 'bottom', 'bottom-start', 'bottom-end', 'left', 'left-start', 'left-end'
 */
export const Popover = ({children, render, placement = 'bottom-start', className}) => {
    const [open, setOpen] = useState(false);
    const {x, y, reference, floating, strategy, refs, update, context} = useFloating({
        open,
        onOpenChange: setOpen,
        middleware: [offset(10), flip(), shift()],
        placement: placement,
    });

    const id = useId();
    const labelId = `${id}-label`;
    const descriptionId = `${id}-description`;

    const {getReferenceProps, getFloatingProps} = useInteractions([
        useClick(context),
        useRole(context),
        useDismiss(context),
        useFocusTrap(context, {
            modal: false,
            order: ["reference", "content"]
        })
    ]);

    useEffect(() => {
        if (refs.reference.current && refs.floating.current && open) {
            return autoUpdate(refs.reference.current, refs.floating.current, update);
        }
    }, [open, update, refs.reference, refs.floating]);

    const handleOnClose = () => {
        setOpen(false);
        refs.reference.current && refs.reference.current.focus();
    }

    return <Fragment>
        {isValidElement(children) && cloneElement(children, getReferenceProps({ref: reference}))}

        {open && <div {...getFloatingProps({
                className: `popover-container ${className ? className : ''}`,
                ref: floating,
                style: {position: strategy, top: y ?? "", left: x ?? ""},
                "aria-labelledby": labelId,
                "aria-describedby": descriptionId
            }
        )}>
            {render({labelId, descriptionId, close: handleOnClose})}
        </div>}
    </Fragment>
};
