// Parameter documentation: https://developers.google.com/youtube/player_parameters#cc_load_policy

import React, {useRef, useState} from 'react';
import {PLACEHOLDER} from "../../static";
import {YOUTUBE_REGEX} from "../../actions/";
import "plyr-react/plyr.css";
import {PlyrVideoPlayer} from "./plyr-video-player";

const YoutubePlayer = ({media}) => {
    const plyrRef = useRef();
    const [clicked, setClicked] = useState(false);
    const [videoId, setVideoId] = useState(() => {
        YOUTUBE_REGEX.lastIndex = 0;
        const videoId = YOUTUBE_REGEX.exec(media.url);
        return videoId && videoId.length > 1 ? videoId[1] : null;
    })

    const resolveThumbnail = () => {
        if (!media.thumbnail) {
            return videoId ? `https://img.youtube.com/vi/${videoId}/hqdefault.jpg` : '';
        }
        return media.thumbnail;
    }

    const renderVideo = (media) => {

        if (clicked) {
            return <PlyrVideoPlayer ref={plyrRef} source={
                {
                    type: "video",
                    sources: [{src: videoId, provider: "youtube"}],
                }
            }/>
        } else return (
            <a href='' onClick={event => {
                event.preventDefault();
                setClicked(true);
            }}>

                <img className="card-img" src={PLACEHOLDER} data-src={resolveThumbnail()}/>
            </a>
        );
    }

    if (!videoId) return null;

    return <div className='youtube-player'>
        {renderVideo()}
    </div>
}

export default YoutubePlayer;