import {
    BILLABLE,
    FREE_OF_CHARGE,
    ONLY_ME_ACCESS,
    ONLY_MEMBERS_ACCESS,
    PRIVATE_ACCESS,
    PUBLIC_ACCESS,
    RESTRICTED_ACCESS,
    STATE_ACTIVE,
    STATE_ARCHIVED,
    STATE_BLOCKED,
    STATE_CANCELLED,
    STATE_DELETED
} from "../../../actions/spaces";
import {TooltipIcon} from "../buttons/nav-buttons";
import {
    BlockedIcon,
    CancelledIcon,
    DoorClosedIcon,
    DoorOpenIcon,
    GroupIcon,
    LockIcon,
    MembersOnlyIcon,
    PublicIcon,
    PublicOffIcon
} from "./nav-icons";
import {LocalAtm, MoneyOff} from "@material-ui/icons";
import React from "react";
import {useLanguage} from "../../configuration/configuration";

// other props might be for ex. className='mr-2'
const SpaceAccessIcon = ({access, verbose, ...otherProps}) => {
    const Lang = useLanguage();

    let icon;
    let text = null;
    switch (access) {
        case PUBLIC_ACCESS:
            text = verbose && Lang.access.public.text;
            icon = <TooltipIcon title={Lang.access.public.tooltip}><PublicIcon small/></TooltipIcon>;
            break;
        case PRIVATE_ACCESS:
            text = verbose && Lang.access.loggedInOnly.text;
            icon = <TooltipIcon title={Lang.access.loggedInOnly.tooltip}><PublicOffIcon small/></TooltipIcon>;
            break;
        case RESTRICTED_ACCESS:
            text = verbose && Lang.access.friendsOnly.text('');
            icon = <TooltipIcon title={Lang.access.friendsOnly.tooltip}><GroupIcon small/></TooltipIcon>;
            break;
        case ONLY_ME_ACCESS:
            text = verbose && Lang.access.onlyMe.text;
            icon = <TooltipIcon title={Lang.access.onlyMe.tooltip}><LockIcon small/></TooltipIcon>;
            break;
        case ONLY_MEMBERS_ACCESS:
            text = verbose && Lang.access.onlyMembers.text;
            icon = <TooltipIcon title={Lang.access.onlyMembers.tooltip}><MembersOnlyIcon small/></TooltipIcon>;
            break;
    }

    return <span {...otherProps}>{icon} {text && text}</span>
}

// other props might be for ex. className='mr-2'
export const SpaceStateIcon = ({state, ...otherProps}) => {
    const Lang = useLanguage();

    let icon;
    switch (state) {
        case STATE_ACTIVE:
            icon = <TooltipIcon title={Lang.info.status.active}><DoorOpenIcon/></TooltipIcon>;
            break;
        case STATE_BLOCKED:
            icon = <TooltipIcon title={Lang.info.status.blocked}><BlockedIcon fontSize='small'/></TooltipIcon>;
            break;
        case STATE_DELETED:
            icon = <TooltipIcon title={Lang.info.status.closed}><DoorClosedIcon/></TooltipIcon>;
            break;
        case STATE_ARCHIVED:
            icon = <TooltipIcon title={Lang.info.status.archived}><LockIcon/></TooltipIcon>;
            break;
        case STATE_CANCELLED:
            icon = <TooltipIcon title={Lang.info.status.cancelled}><CancelledIcon/></TooltipIcon>;
            break;
        case 'NONE':
        case 'HIDDEN':
        case 'SHARED':
            icon = <TooltipIcon title={Lang.access.onlyMe.tooltip}><LockIcon/></TooltipIcon>;
    }

    return <span {...otherProps} >{icon}</span>
}

export const SpaceFeesIcon = ({fees, ...otherProps}) => {
    const Lang = useLanguage();

    let icon;
    switch (fees) {
        case BILLABLE:
            icon = <TooltipIcon title={Lang.info.fees.billable}><LocalAtm/></TooltipIcon>;
            break;
        case FREE_OF_CHARGE:
        default:
            icon = <TooltipIcon title={Lang.info.fees.free}><MoneyOff/></TooltipIcon>;
            break;
    }

    return <span {...otherProps} >{icon}</span>
}

export default SpaceAccessIcon;