import {useState} from "react";
import {asyncCreateComment, asyncFetchComments, NONE_STATUS, PENDING_STATUS, SUCCESS_STATUS} from "../../actions";
import {useDispatch} from "react-redux";
import {usePostComments} from "../../selectors";

const useAsyncFetchComments = (postId, initialCount) => {
    const [status, setStatus] = useState(NONE_STATUS);
    const [count, setCount] = useState(initialCount);
    const comments = usePostComments(postId);
    const dispatch = useDispatch();

    const fetchComments = (username, postId) => {
        setStatus(PENDING_STATUS);
        dispatch(asyncFetchComments(username, postId, (comments, postId) => {
                setStatus(SUCCESS_STATUS);
                setCount(comments.length);
            })
        );
    };

    const createComment = (username, comment) => {
        if (comment.length > 0) {
            setStatus(PENDING_STATUS);
            dispatch(asyncCreateComment(username, postId,
                {text: comment, username: username}, (comments) => {
                    //forceUpdate();
                    setStatus(SUCCESS_STATUS);
                })
            );
        }
    }

    return {
        fetchComments: fetchComments,
        createComment: createComment,
        comments: comments, count: count, status: status
    }
}
export default useAsyncFetchComments;