import {useCallback, useEffect, useRef, useState} from "react";
import {asyncFetchPostsPage, FETCH_PAGE_SIZE} from "../../actions";
import moment from "moment";
import {showForceVisibleImages, showVisibleImages} from "../../actions/image-handler";
import {useDispatch, useSelector} from "react-redux";

// spacename is one of 'home' or 'generic'
const useAsyncFetchPosts = (username, spacename, page) => {
    const pageRef = useRef({next: page.next, first: page.first, last: page.last, moment: page.moment});
    const [status, setStatus] = useState('pending');
    const posts = useSelector(state => state.posts);
    const dispatch = useDispatch();

    const fetchPostPage = useCallback((username, spacename, pageNumber) => {
        setStatus('pending');
        dispatch(asyncFetchPostsPage(username, spacename, pageNumber, FETCH_PAGE_SIZE, pageData => {
                pageRef.current = {
                    next: pageData.number + 1,
                    first: pageData.first,
                    last: pageData.last,
                    when: moment()
                };
                (pageData.last || pageData.first) ? showForceVisibleImages() : showVisibleImages();
                setStatus('success');
            })
        );
    }, [username, spacename, page]);

    useEffect(() => {
        fetchPostPage(username, spacename, page.next);
    }, [username, spacename, page]);

    return {posts: posts, status: status, page: pageRef.current}
}

export default useAsyncFetchPosts;