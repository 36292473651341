import {NONE_STATUS, PENDING_STATUS, SUCCESS_STATUS} from "../../actions";
import axios from "axios";
import {getPeekMenuByStateUrl, getPeekPublicMenuByStateUrl} from "../../actions/environment";
import {authConfig} from "../../actions/local-storage";
import {useState} from "react";
import useErrorHandler from "./use-error-handler";

const useFetchPageMenu = () => {
    const [status, setStatus] = useState(NONE_STATUS);
    const [pages, setPages] = useState([]);
    const {handleError, error} = useErrorHandler(setStatus);

    const fetchPeekMenuPages = (username, state = 'active') => {
        console.log('Fetching authorized peek menu pages');

        setStatus(PENDING_STATUS);
        axios.get(getPeekMenuByStateUrl(username, state), authConfig())
            .then(response => {
                setPages(response.data);
                setStatus(SUCCESS_STATUS);
            })
            .catch(error => {
                console.log('FETCH PEEK MENU', error);
                {!error && handleError(error, () => fetchPeekMenuPages(username, state))}
            })
    }

    const fetchPeekPublicMenuPages = (username, state = 'active') => {
        console.log('Fetching public peek menu pages');

        setStatus(PENDING_STATUS);
        axios.get(getPeekPublicMenuByStateUrl(username, state), authConfig())
            .then(response => {
                setPages(response.data);
                setStatus(SUCCESS_STATUS);
            })
            .catch(error => {
                console.log('FETCH PEEK MENU', error);
                {!error && handleError(error, () => fetchPeekMenuPages(username, state))}
            })
    }

    return {
        fetchMenuPages: fetchPeekMenuPages,
        fetchPublicMenuPages: fetchPeekPublicMenuPages,
        pages: pages, status: status, error: error
    }
}

export default useFetchPageMenu;