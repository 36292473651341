import React, {forwardRef, useEffect} from "react";
import {usePlyr} from "plyr-react";

// use the video player through a forward ref hook - see react-aptor for infos
export const PlyrVideoPlayer = forwardRef((props, ref) => {
    const {source, options = null, autoplay= null,...rest} = props
    const raptorRef = usePlyr(ref, {
        source,
        options,
    })

    useEffect(() => {
        autoplay && raptorRef.current && raptorRef.current.plyr && raptorRef.current.plyr.play();
    }, []);

    return <video ref={raptorRef} className="plyr-react plyr" {...rest} />
})
