import {useLanguage} from "../configuration/configuration";

export const LIKE = 'LIKE';
export const LOVE = 'LOVE';
export const HAHA = 'HAHA';
export const WOW = 'WOW';
export const SAD = 'SAD';
export const ANGRY = 'ANGRY';

export const ReactionInfo = (Lang) => {
    return({
        LIKE: Lang.tooltip.likes.like,
        LOVE: Lang.tooltip.likes.love,
        HAHA: Lang.tooltip.likes.haha,
        WOW: Lang.tooltip.likes.wow,
        SAD: Lang.tooltip.likes.sad,
        ANGRY: Lang.tooltip.likes.angry,
        })
}