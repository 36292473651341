import React, {useContext} from "react";
import {SlideoutContext} from "../slideout-navigation/slideout-provider";
import {getUserAvatar} from "../../actions/environment";
import NavigationUser from "./navigation-user";
import {useAuthorization, useSiteConfiguration} from "../../selectors";
import {HomeRounded} from "@material-ui/icons";
import {TooltipLink} from "./buttons/nav-buttons";
import {useConfiguration, useLanguage} from "../configuration/configuration";

const NavigationSlideoutToggler = () => {
    const {toggle} = useContext(SlideoutContext);
    const {isAuthorized, principal} = useAuthorization();
    const {homePage} = useSiteConfiguration();
    const Lang = useLanguage();

    const name = principal ? principal.firstname : 'Loading..';
    const avatar = principal ? getUserAvatar(principal) : 'Loading..';

    const handleOnToggle = (event) => {
        event.preventDefault();
        toggle();
    }

    return <div className='d-flex'>
            <TooltipLink className='nav-link box-aligned justify-content-md-between' to={homePage}>
                <HomeRounded className='mr-2'/>
                <span className='nav-item-hide'>{Lang.nav.header.home}</span>
            </TooltipLink>
            <button className="navbar-toggler" type="button" data-toggle="offcanvas-collapse">
                {isAuthorized && <NavigationUser avatar={avatar} name={name} to={`/${principal.username}/home`}/>}
                <span className="navbar-toggler-icon" onClick={handleOnToggle}/>
            </button>
        </div>
};

export default NavigationSlideoutToggler;