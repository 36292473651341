import {asyncSharePost, NONE_STATUS, PENDING_STATUS, SUCCESS_STATUS} from "../../actions";
import {notify} from "../notifications/notify";
import {useState} from "react";
import {useDispatch} from "react-redux";
import {useLanguage} from "../configuration/configuration";
import {useAuthorization} from "../../selectors";

const useAsyncSharePost = () => {
    const [status, setStatus] = useState(NONE_STATUS);
    const [post, setPost] = useState(null);
    const {principalUsername} = useAuthorization();
    const Lang = useLanguage();
    const dispatch = useDispatch();

    const sharePost = (username, spaceId, postId, spaceName, comment=null, setOpen=null) => {
        setStatus(PENDING_STATUS);
        dispatch(asyncSharePost(username, spaceId, postId, {comment: comment},
            post => {
                setStatus(SUCCESS_STATUS);
                setPost(post);
                notify(Lang.dialog.sharePost.info(spaceName));
                setOpen && setOpen(false);
            })
        );
    }

    return {sharePost: sharePost, post: post, status}
}
export default useAsyncSharePost;