import {format, parse} from "date-fns";
import {
    GENERIC_SPACE,
    GROUP_SPACE,
    MENU_SPACE,
    SPACE_SUBTYPE_DEFAULT,
    SPACE_SUBTYPE_GROUP,
    SPACE_SUBTYPE_MENU
} from "../../../actions/spaces";

const ISO_DATE_FORMAT = 'yyyy-MM-dd';
const ISO_TIME_FORMAT = 'HH:mm:ss';

// space generic data as returned by the server - also used for update and create
export const SpacesFormData = {
    type: '',                       // defined string EVENT, GENERIC, GALLERY
    subType: '',                    // defined string DEFAULT, ACADEMIC, GROUP
    name: '',                       // <string>
    description: '',                // <string>
    generalInformation: '',         // <string>
    access: '',                     // <string>
    membership: '',                 // FREE, REGULAR, SILVER, GOLD, PLATINUM, VIP, CLUB
    fees: '',                       // <string>
    feesDescription: '',            // <string>
    hygiene: '',                    // <string>
    hygieneDescription: '',         // <string>
    theVenue: '',                   // <string>
    contactInformation: '',
    accommodation: '',              // <string>
    travelInformation: '',          // <string>
    ranking: '100',                 // <string>
    category: '',
    startDate: '',                  // string ISO_DATE yyyy-MM-dd
    fromStartTime: '',              // string ISO_LOCAL_TIME HH:mm:ss
    untilStartTime: '',             // string ISO_LOCAL_TIME HH:mm:ss
    endDate: '',                    // string ISO_DATE yyyy-MM-dd
    fromEndTime: '',                // string ISO_LOCAL_TIME HH:mm:ss
    untilEndTime: '',               // string ISO_LOCAL_TIME HH:mm:ss
    calendarDates: [],              // array of CalendarDate in ISO_DATE yyyy-MM-dd and ISO_LOCAL_TIME HH:mm:ss
    street: '',
    city: '',
    areacode: '',
    country: '',
    locationName: '',
}

// helper for the client
export const DatesFormData = {
    startDate: new Date(),          // <date> with time
    fromStartTime: null,
    untilStartTime: null,
    endDate: null,                  // <date> with time initially none
    fromEndTime: null,
    untilEndTime: null,
}

// this is a two-way constructor - used by both incoming and outgoing data, with different formatting
export const CalendarDate = (date = null, startTime = null, endTime = null) => {
    return {
        date: date,
        startTime: startTime,
        endTime: endTime,
        description: null,
        name: null,
    }
}

// helper for the client
export const AddressFormData = {
    locationName: '',
    street: '',                 // street and number
    city: '',
    areacode: '',
    country: 'Deutschland'      // default
}

// from ISO_DATE
const parseISODate = (dateAsString, defaultDate = new Date()) => {
    return dateAsString ? parse(dateAsString, ISO_DATE_FORMAT, new Date()) : defaultDate;
}

// from ISO_LOCAL_TIME
const parseISOTime = (timeAsString, defaultTime = new Date()) => {
    return timeAsString ? parse(timeAsString, ISO_TIME_FORMAT, new Date()) : defaultTime;
}

// to ISO_DATE
const formatISODate = (date) => {
    return date ? format(date, ISO_DATE_FORMAT) : null;
}

// to ISO_LOCAL_TIME
const formatISOTime = (time) => {
    return time ? format(time, ISO_TIME_FORMAT) : null;
}

// populate internal formData with data coming from server for use by the client (partial update s. dates and address below)
export const populateClientFormData = (formData, space, spacedata) => {
    return {
        ...formData,
        'type': space.type,
        'subType': space.subType,
        'name': space.name,
        'description': space.description,
        'access': space.access,
        'membership': space.membership,
        'fees': space.fees,
        'category': space.category,
        'hygiene': space.hygiene,
        'ranking': space.ranking,
        'feesDescription': spacedata.feesDescription,
        'hygieneDescription': spacedata.hygieneDescription,
        'generalInformation': spacedata.generalInformation,
        'theVenue': spacedata.theVenue,
        'contactInformation': spacedata.contactInformation,
        'accommodation': spacedata.accommodation,
        'travelInformation': spacedata.travelInformation,
    };
}

// populates client calendarDates array with data from server, lower dates first
export const populateClientCalendarDates = (calendarDates, spacedata) => {
    if (!spacedata.calendarDates) return [CalendarDate(new Date())];

    const populatedDates = spacedata.calendarDates
        .sort((a, b) => (a.date + a.startTime) < (b.date + b.startTime) ? -1 : 0)
        .map(entry => {
            const calendarDate = CalendarDate();
            calendarDate.date = parseISODate(entry.date);
            calendarDate.startTime = parseISOTime(entry.startTime, null);
            calendarDate.endTime = parseISOTime(entry.endTime, null);
            calendarDate.description = entry.description;
            calendarDate.name = entry.name;
            return calendarDate;
        });
    return [...calendarDates, ...populatedDates];
}

// populates calendarDates as required by the server for update or create of spaces
export const populateServerCalendarDates = (calendarDates) => {
    return calendarDates
        .map(entry => {
            const calendarDate = CalendarDate();
            calendarDate.date = formatISODate(entry.date);
            calendarDate.startTime = formatISOTime(entry.startTime, null);
            calendarDate.endTime = formatISOTime(entry.endTime, null);
            calendarDate.description = entry.description;
            calendarDate.name = entry.name;
            return calendarDate;
        });
}

// populate internal addressData with data coming from server for use by the client
export const populateClientAddressData = (addressData, address) => {
    return {
        ...addressData,
        'locationName': address.locationName,
        'street': address.street,
        'city': address.city,
        'areacode': address.areacode,
        'country': address.country,
    };
}

export const populateServerGenericData = (formData, calendarDates, addressData) => {
    // override space type at this time
    if (formData['type'] === MENU_SPACE) {
        formData['type'] = GENERIC_SPACE;
        formData['subType'] = SPACE_SUBTYPE_MENU;
    } else if (formData['type'] === GROUP_SPACE) {
        formData['type'] = GENERIC_SPACE;
        formData['subType'] = SPACE_SUBTYPE_GROUP;
    } else {
        formData['subType'] = SPACE_SUBTYPE_DEFAULT;
    }
    return {
        ...formData,
        'calendarDates': populateServerCalendarDates(calendarDates),
        'locationName': addressData.locationName,
        'street': addressData.street,
        'city': addressData.city,
        'areacode': addressData.areacode,
        'country': addressData.country,
    };
}