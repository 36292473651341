import {NONE_STATUS, PENDING_STATUS, SUCCESS_STATUS} from "../../actions";
import axios from "axios";
import {getCreateGalleryBySpaceUrl, getCreateSpaceUrl, getUpdateSpaceUrl} from "../../actions/environment";
import {authConfig} from "../../actions/local-storage";
import {useState} from "react";
import useErrorHandler from "./use-error-handler";
import {useAuthorization} from "../../selectors";

/**
 * helper hook for creating and updating spaces synchronously (without redux)
 * data must contain all needed fields as in manage-space-form.js (s. SpacesFormData for details)
 */
const useUpdateSpace = () => {
    const [status, setStatus] = useState(NONE_STATUS);
    const [space, setSpace] = useState(null);
    const {principalUsername} = useAuthorization();
    const {handleError, error} = useErrorHandler(setStatus);

    // data must be fully populated s. populateServerGenericData
    const updateSpace = (spaceId, data, callback) => {
        console.log('Updating space', spaceId, data);

        setStatus(PENDING_STATUS);
        axios.post(getUpdateSpaceUrl(principalUsername, spaceId), data, authConfig())
            .then(response => {
                setSpace(response.data);
                setStatus(SUCCESS_STATUS);
                callback && callback(response.data);
            })
            .catch(error => {
                console.log('UpdateSpace error', error);
                handleError(error, () => updateSpace(spaceId, data, callback));
            })
    }

    // data must be fully populated s. populateServerGenericData
    // for galleries binds the space to a user (principal)
    // create space by user
    const createSpace = (data, callback) => {
        console.log('Creating space', data);
        setStatus(PENDING_STATUS);
        axios.post(getCreateSpaceUrl(principalUsername), data, authConfig())
            .then(response => {
                setSpace(response.data);
                setStatus(SUCCESS_STATUS);
                callback && callback(response.data);
            })
            .catch(error => {
                console.log('createSpace error', error);
                handleError(error, () => createSpace(data, callback));

            })
    }

    // create space by group space - ie bind the gallery to a space and not to a user
    const createSpaceBySpace = (spaceId, data, callback) => {
        console.log('Creating space gallery', data);
        setStatus(PENDING_STATUS);
        axios.post(getCreateGalleryBySpaceUrl(principalUsername, spaceId), data, authConfig())
        .then(response => {
            setStatus(SUCCESS_STATUS);
            setSpace(response.data);
            callback && callback(response.data);
        })
        .catch(error => {
            console.log('createSpace gallery error', error);
            handleError(error, () => createSpaceBySpace(spaceId, data, callback));
        })
    }

    return {
        updateSpace: updateSpace,
        createSpace: createSpace,
        createSpaceBySpace: createSpaceBySpace,
        space, status, error}
}

export default useUpdateSpace;