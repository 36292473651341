import {useLanguage} from "../configuration/configuration";
import React, {useState} from "react";
import {isRegistration as checkRegistration, useAuthorization} from "../../selectors";
import {useDispatch, useSelector} from "react-redux";
import {logoutRequest} from "../../actions";
import {DigitalCardIcon, UserPenIcon} from "./icons/nav-icons";
import {TooltipLink} from "./buttons/nav-buttons";
import {
    AccountCircle,
    NavigateNext,
    PersonAdd,
    PowerSettingsNew,
    SettingsApplicationsRounded
} from "@material-ui/icons";
import ShowDigitalCard from "../dialogs/user/show-digital-card";

// helper for navigation dropdown actions menu including login/logout
const NavigationUserActionsDropdown = () => {
    const [showDigitalCard, setShowDigitalCard] = useState(false);
    const dispatch = useDispatch();
    const {isAuthorized, isSuperUser, isAdmin, authorization} = useAuthorization();
    const isRegistration = useSelector(state => checkRegistration(state));
    const Lang = useLanguage();

    const logout = (event) => {
        event.preventDefault();
        dispatch(logoutRequest());
    }

    const onShowDigitalCard = () => {
        setShowDigitalCard(!showDigitalCard);
    }

    return <div className="dropdown-menu navbar-actions-dropdown">
        {isAuthorized && isSuperUser && <TooltipLink className="dropdown-item" to="/manage/site">
            <span><SettingsApplicationsRounded className='mr-1'/>{Lang.nav.submenu.manageSite}</span>
        </TooltipLink>}

        {isAuthorized && <TooltipLink className="dropdown-item" to="/update/account">
            <span><UserPenIcon className='font-1-bigger mr-1'/>{Lang.nav.submenu.yourAccount}</span>
        </TooltipLink>}

        {isAuthorized && <div className="dropdown-divider"/>}
        {(isSuperUser || isRegistration) && <TooltipLink className='dropdown-item' to="/create/account">
            <span><PersonAdd className='mr-1'/>{Lang.nav.submenu.createAccount}</span>
        </TooltipLink>}

        {/*{isAuthorized && <Link className="dropdown-item"*/}
        {/*                       to="/delete/account">{Lang.nav.submenu.deleteAccount}</Link>}*/}

        {/*{isAuthorized && <div className="dropdown-divider"/>}*/}
        {isAuthorized && isSuperUser && <TooltipLink className="box-baseline dropdown-item" to="/manage/users">
            <span><NavigateNext className='ml--2'/>{Lang.nav.submenu.manageUsers}</span>
        </TooltipLink>}
        {isAuthorized && (isSuperUser || isAdmin) && <TooltipLink className="dropdown-item" to="/manage/spaces">
            <span><NavigateNext className='ml--2'/>{Lang.nav.submenu.manageSpaces}</span>
        </TooltipLink>}

        {isAuthorized && (isSuperUser || isAdmin) && <TooltipLink className="dropdown-item" to="/manage/pages">
            <span><NavigateNext className='ml--2'/>{Lang.nav.submenu.managePages}</span>
        </TooltipLink>}

        {isAuthorized && <TooltipLink className="dropdown-item" to="/manage/galerie">
            <span><NavigateNext className='ml--2'/>{Lang.nav.submenu.manageGalleries}</span>
        </TooltipLink>}

        {isAuthorized && isSuperUser && <TooltipLink className="dropdown-item" to="/manage/widgets">
            <span><NavigateNext className='ml--2'/>{Lang.nav.submenu.newWidget}</span>
        </TooltipLink>}

        <div className="dropdown-divider"/>
        <TooltipLink className='box-baseline dropdown-item mt-1' to='#' onClick={onShowDigitalCard}>
            <span><DigitalCardIcon className='mr-1'/>{Lang.nav.submenu.digitalCard.title}</span>
        </TooltipLink>
        <TooltipLink className='box-baseline dropdown-item mt-1' to='/login'>
            <span><AccountCircle className='mr-1'/>{Lang.nav.submenu.login}</span>
        </TooltipLink>
        <TooltipLink className='box-baseline dropdown-item mt-1' to='#' onClick={logout}>
            <span><PowerSettingsNew className='mr-1'/>{Lang.nav.submenu.logout}</span>
        </TooltipLink>

        {showDigitalCard && <ShowDigitalCard isOpen={showDigitalCard} setIsOpen={setShowDigitalCard}/>}
    </div>
}

export default NavigationUserActionsDropdown;