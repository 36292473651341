import Sortable from "sortablejs";

import {Cancel} from "@material-ui/icons";
import React, {useEffect, useRef} from "react";
import {getImageServerSmallUrl, getYoutubeThumbnail} from "../../actions/environment";
import {asyncDeleteMedia, asyncReorderPostMedia} from "../../actions";
import {localDeleteMedia} from "../../actions/spaces";
import {useDispatch} from "react-redux";
import {useLanguage} from "../configuration/configuration";
import {showVisibleImages} from "../../actions/image-handler";
import {notify} from "../notifications/notify";

const MediaImageEditor = ({username, post}) => {
    const inProgressRef = useRef(false);
    const mediaPreviewRef = useRef(null);
    const Lang = useLanguage();
    const dispatch = useDispatch();

    useEffect(() => {
        if (mediaPreviewRef) {
            Sortable.create(mediaPreviewRef.current,
                {animation: 150, onEnd: handleOnSorted});
        }
    })

    const updateMediaSorting = sortedMedia => {
        inProgressRef.current = true;
        dispatch(asyncReorderPostMedia(username, post.id, {positions: sortedMedia}, post => {
                inProgressRef.current = false;
                notify(Lang.dialog.editMedia.confirm(sortedMedia.length));
                showVisibleImages();
            })
        );
    }

    const computeImageSorting = (children) => {
        const sortedImages = [];
        [...children].forEach((child, idx) => {
            child.dataset.media && sortedImages.push({
                mediaId: child.dataset.media,
                position: idx,
                // old: child.dataset.position
            });
        });
        return sortedImages;
    }

    const handleOnSorted = (sortableEvent) => {
        if (inProgressRef.current) return;
        const sortedImages = computeImageSorting(sortableEvent.to.children);
        updateMediaSorting(sortedImages);
    }

    const deleteMedia = (postId, mediaId) => {
        dispatch(asyncDeleteMedia(username, postId, mediaId, post => {
                dispatch(localDeleteMedia([{id: mediaId}]));
                showVisibleImages();
                notify(Lang.button.deleteImage.confirm(post.id));
            })
        );
    };

    const handleRemove = media => event => {
        event.preventDefault();
        deleteMedia(post.id, media.id);
    }

    // currently, support only for youtube and regular images in edit mode
    const renderMediaPreview = () => {
        return post.media
            .filter(media => media.type === 'PICTURE' || media.type === 'YOUTUBE')
            .map((media, idx) => {
                if (media.type === 'YOUTUBE') {
                    return <div key={media.id} className='youtube-preview'
                                data-position={media.position}
                                data-media={media.id}>
                        <div className='media-upload-item'>
                            <img src={getYoutubeThumbnail(media.url)}/>
                            <span className='media-upload-icon' onClick={handleRemove(media)}>
                                <Cancel fontSize='small'/>
                            </span>
                        </div>
                    </div>
                }
                return <div key={media.id} className='media-upload-item'
                            data-position={media.position}
                            data-media={media.id}>
                    <img src={getImageServerSmallUrl(media.url)}/>
                    <span className='media-upload-icon' onClick={handleRemove(media)}>
                        <Cancel fontSize='small'/>
                    </span>
                </div>
            });
    }

    if (!post) return null;
    return <div className='media-rich-editor'>
        <div className='media-upload-preview' ref={mediaPreviewRef}>
            {renderMediaPreview()}
        </div>
    </div>


}

export default MediaImageEditor;