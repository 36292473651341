import {getPostsUploadUrl} from "../../actions/environment";
import {asyncUpdatePost, PENDING_STATUS, SUCCESS_STATUS} from "../../actions";
import {localUpdateMedia} from "../../actions/spaces";
import {showForceVisibleImages} from "../../actions/image-handler";
import {notify as alertInfo} from "../notifications/notify";
import {useDispatch} from "react-redux";
import {useState} from "react";
import {useAuthorization} from "../../selectors";
import {useLanguage} from "../configuration/configuration";
import useErrorHandler from "./use-error-handler";
import {uploadFilesAsyncWorker} from "../dropzone-uploader/file-uploader";

const usePostFileUploader = () => {
    const [status, setStatus] = useState('none');
    const {principalUsername} = useAuthorization();
    const {handleError, error} = useErrorHandler(setStatus);
    const dispatch = useDispatch();
    const Lang = useLanguage();

    /**
     * upload images and text to server
     */
    const uploadDataAndImages = async (post, spacename, text, files) => {
        setStatus(PENDING_STATUS);

        try {
            const mediaUploadUrl = getPostsUploadUrl(principalUsername);
            const media= await uploadFilesAsyncWorker(mediaUploadUrl, files, text);

            dispatch(asyncUpdatePost(principalUsername, {text: text, media: media}, post.id, updated => {
                if (post.media && media.length) {
                    const reduced = updated.media.filter(media => post.media.every(m1 => m1.id !== media.id));
                    dispatch(localUpdateMedia(reduced));
                    showForceVisibleImages();
                }
                setStatus(SUCCESS_STATUS);
                alertInfo(Lang.post.upload.success(updated.space.name));
            }));

        } catch (error) {
            console.log('Image upload in usePostFileUploader', error);
            handleError(error, () => uploadDataAndImages(post, spacename, text, files));
        }

    }

    // not yet supported
    const uploadEmbeddedVideo = (spacename, text, embedded) => {
        console.log('VIDEO UPLOAD NOT SUPPORTED');
    }

    return {uploadFiles: uploadDataAndImages, uploadVideo: uploadEmbeddedVideo, status: status, error: error}
}

export default usePostFileUploader;