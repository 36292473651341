import React, {Fragment, useState} from "react";
import {useLanguage} from "../configuration/configuration";
import {NavigationGroup, NavigationRow} from "../navigation/layout/nav-layout";
import {RoundedButton} from "../navigation/buttons/nav-buttons";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import {FormRow} from "../forms/manage-spaces/manage-space-form";
import MarkdownTextarea from "./markdown-textarea";
import {cn} from "../util/text-utils";

/**
 * same parameters as for <textarea> including a label. toggles between edit mode and html display mode
 *
 * @param label
 * @param id
 * @param otherProps
 */
const FormMarkdownTextarea = ({label, id, spacing, ...otherProps}) => {
    const [toggleHtml, setToggleHtml] = useState(false);
    const Lang = useLanguage();

    const handleOnToggleHtml = event => {
        event.preventDefault();
        setToggleHtml(!toggleHtml);
    }

    return <div className={cn('mt-2', [spacing])}>
        <NavigationRow>
            <NavigationGroup>
                <label className="form-check-label float-left" htmlFor={id}>
                    {label}
                </label>
            </NavigationGroup>
            <NavigationGroup>
                <RoundedButton onClick={handleOnToggleHtml} title={Lang.markdown.toggle.text}>
                    {!toggleHtml ? <Visibility/> : <VisibilityOff/>}
                </RoundedButton>
            </NavigationGroup>
        </NavigationRow>

        <FormRow>
            <MarkdownTextarea id={id} {...otherProps} onToggle={toggleHtml}/>
            <div className="form-text text-muted text-left mb-2">
                <span className='badge bg-secondary rounded-pill mr-1'>MARKDOWN</span>
                {Lang.updateSpace.generalInformation.information}
            </div>
        </FormRow>
    </div>
}

export default FormMarkdownTextarea;
