import {useCallback, useEffect, useState} from "react";
import {asyncFetchHomeData} from "../../actions/spaces";
import {useHomeData} from "../../selectors";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import {NONE_STATUS, PENDING_STATUS, SUCCESS_STATUS} from "../../actions";

const HOME_SPACE = 'home';

// hook loads home data from server instead of just returning whatever is in the store,
// which gets reset when component is destroyed
const useAsyncFetchHomeData = () => {
    const [status, setStatus] = useState(NONE_STATUS);
    const {username} = useParams();
    const {homedata} = useHomeData();
    const dispatch = useDispatch();

    const fetchHomeData = useCallback((username) => {
        setStatus(PENDING_STATUS);
        dispatch(asyncFetchHomeData(username, HOME_SPACE, data => {
                setStatus(SUCCESS_STATUS);
            })
        );
    }, []);

    useEffect(() => {
        fetchHomeData(username);

        // return () => {
        //     setStatus('pending')
        //     dispatch(updateHomeData(null));
        // }
    }, [username]);

    return {homedata: homedata, status: status}
}

export default useAsyncFetchHomeData;