import {useState} from "react";
import {EVENT_SPACE} from "../../actions/spaces";
import {getPeekParentSpacesUrl, getPeekPublicSpacesUrl} from "../../actions/environment";
import {useAuthorization, useSiteConfiguration} from "../../selectors";
import {NONE_STATUS, PENDING_STATUS, SUCCESS_STATUS} from "../../actions";
import axios from "axios";
import {authConfig} from "../../actions/local-storage";
import useErrorHandler from "./use-error-handler";

const usePeekEventSpaces = () => {
    const [status, setStatus] = useState(NONE_STATUS);
    const [events, setEvents] = useState(null);
    const {isAuthorized} = useAuthorization();
    const {handleError, error} = useErrorHandler(setStatus);

    // authorized
    const peekUserParentEvents = username => {
        setStatus(PENDING_STATUS);
        axios.get(getPeekParentSpacesUrl(username, EVENT_SPACE), authConfig())
        .then(response => {
            setEvents(response.data);
            setStatus(SUCCESS_STATUS);
        })
        .catch(error => {
            console.log('peekUserParentEvents', error.response);
            handleError(error, () => peekUserParentEvents(username));
        })
    }

    // not authorized
    const peekPublicParentEvents = username => {
        setStatus(PENDING_STATUS);
        axios.get(getPeekPublicSpacesUrl(username, EVENT_SPACE), authConfig())
        .then(response => {
            setEvents(response.data);
            setStatus(SUCCESS_STATUS);
        })
        .catch(error => {
            console.log('peekPublicParentEvents', error.response);
            handleError(error, () => peekPublicParentEvents());
        })
    }

    const peekEventSpaces = (username, isParent) => {
        if (isAuthorized) {
            peekUserParentEvents(username)
        } else {
            peekPublicParentEvents(username);
        }
    }

    return {
        peekEventSpaces: peekEventSpaces,
        events: events,
        status: status}
}

export default usePeekEventSpaces;