export function getVideoThumbnail(file) {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();

        function snapImage(video, callback) {
            const canvas = document.createElement("canvas");
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;

            const ctx = canvas.getContext("2d");
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            ctx.canvas.toBlob(blob => {
                callback(blob);
            }, 'image/jpeg', 0.75);
        }

        fileReader.onload = function () {
            const blob = new Blob([fileReader.result], {type: file.type});
            const url = URL.createObjectURL(blob);
            const video = document.createElement("video");

            const timeupdate = function () {
                snapImage(video, blob => {
                    URL.revokeObjectURL(url);
                    video.removeEventListener("timeupdate", timeupdate);
                    video.pause();
                });
            };

            video.addEventListener("loadeddata", function () {
                snapImage(video, blob => {
                    URL.revokeObjectURL(url);
                    video.removeEventListener("timeupdate", timeupdate);
                    resolve(blob);
                });
            });
            video.addEventListener("timeupdate", timeupdate);

            video.preload = "metadata";
            video.src = url;
            video.muted = true;
            video.playsInline = true;
            video.play();
        };

        fileReader.readAsArrayBuffer(file);
    })
}
