import React, {Component, createContext} from "react";

export const TogglerContext = createContext({});

// css based toggler - code is present when used
class NavigationToggler extends Component {

    componentDidMount() {
        this.props.onRef(this);
    }

    componentWillUnmount() {
        this.props.onRef(null);
    }

    toggle = (event) => {
        event && event.preventDefault();
        this.togglerRef.classList.toggle('active-show');
        if (this.props.onClose && !this.togglerRef.classList.contains('active-show')) {
            this.props.onClose();
        }
    };

    hide = (event) => {
        event && event.preventDefault();
        this.togglerRef.classList.remove('active-show');
        this.props.onClose && this.props.onClose();
    };

    show = (event) => {
        event && event.preventDefault();
        this.togglerRef.classList.add('active-show');
    };

    isHidden = (event) => {
        event && event.preventDefault();
        return !this.togglerRef.classList.contains('active-show');
    }

    render() {
        const {className, show, onRef, onClose, ...otherProps} = this.props;

        return <TogglerContext.Provider value={{toggle: this.toggle, hide: this.hide, show: this.show, isHidden: this.isHidden}}>
            <div className={`active-space-toggle ${className ? className :''} ${show ? 'active-show' : ''}`} {...otherProps}
                 ref={(ref) => this.togglerRef = ref}>
                {this.props.children}
            </div>
        </TogglerContext.Provider>
    }
}

export default NavigationToggler;