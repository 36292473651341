import {ConfigurationContext, loadLanguageScript} from "../configuration/configuration";
import React, {useContext} from "react";
import {TooltipLink} from "./buttons/nav-buttons";
import {NavigateNext} from "@material-ui/icons";
import {FlagDE, FlagES, FlagGB} from "./icons/nav-icons";

export const localeFlags = {
    'de': <FlagDE/>,
    'es': <FlagES/>,
    'en-GB': <FlagGB/>,
}

// helper for language navigation dropdown actions
const NavigationLanguageDropdown = () => {
    //const Lang = useLanguage();
    const {Lang, setLocale} = useContext(ConfigurationContext);

    const changeLocale = (event, locale) => {
        event.preventDefault();
        setLocale(locale);
    }
    return <div className="dropdown-menu navbar-actions-dropdown">
        <TooltipLink className='dropdown-item' to='#'
                     onClick={(e) => changeLocale(e, 'de')}>
            <span><NavigateNext className='ml--2'/>{Lang.languages.de}</span>
        </TooltipLink>
        <TooltipLink className='dropdown-item' to='#'
                     onClick={(e) => changeLocale(e, 'es')}>
            <span><NavigateNext className='ml--2'/>{Lang.languages.es}</span>
        </TooltipLink>
        <TooltipLink className='dropdown-item' to='#'
                     onClick={(e) => changeLocale(e,'en-GB')}>
            <span><NavigateNext className='ml--2'/>{Lang.languages.en}</span>
        </TooltipLink>
    </div>
}

export default NavigationLanguageDropdown;