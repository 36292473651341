import React, {useEffect, useState} from "react";
import {useIsMobile, useLanguage} from "../../configuration/configuration";
import {WavesTooltipButton, WavesTooltipLink} from "../../navigation/buttons/nav-buttons";
import {useDispatch} from "react-redux";
import {asyncAddFriend, chatEventHandler, EVENT_CHAT_PENDING} from "../../../actions";
import {useAuthorization, useFriendData, useHomeData} from "../../../selectors";
import {DateRange, EventAvailable, PersonAdd, Schedule} from "@material-ui/icons";
import {notify, notifyWarning} from "../../notifications/notify";
import {ChatIcon} from "../../navigation/icons/nav-icons";

const HomeUserNavigation = ({username}) => {
    const {isAuthorized, principalUsername} = useAuthorization();
    const [identity, setIdentity] = useState({isGeneric: false, isSelf: false, isOther: false,});
    const {homedata, isFriend, calendarVisibility, friendsVisibility} = useHomeData();
    const {incomingChat} = useFriendData();
    const isMobile = useIsMobile();

    const Lang = useLanguage();
    const dispatch = useDispatch();

    const handleAddFriend = (event) => {
        dispatch(asyncAddFriend(principalUsername, username, friend => {
                notifyWarning(Lang.user.nav.friendshipRequested(friend.friend.firstname));
            })
        );
    }

    useEffect(() => {
        setIdentity({
            isGeneric: !isAuthorized || (username !== principalUsername),
            isSelf: isAuthorized && username === principalUsername,
            isOther: isAuthorized && username !== principalUsername,
        })
    }, [username]);

    useEffect(() => {
        if (incomingChat) {
            const isSelf = username === principalUsername;
            const isConsumed = incomingChat.state === 'CONSUMED' || incomingChat.state === 'PENDING';
            if (isSelf && !isConsumed) {
                dispatch(chatEventHandler(EVENT_CHAT_PENDING, null));
                notify(Lang.chat.info.newArrived(incomingChat.from));
            }
        }
    }, [incomingChat])

    const renderCalendarButton = () => {
        const firstname = homedata.space.user.firstname;

        const text = identity.isSelf ? Lang.generic.nav.calendar.yours
            : identity.isOther ? Lang.generic.nav.calendar.other(firstname)
                : Lang.generic.nav.calendar.generic

        return <WavesTooltipLink btn small
                                 title={text}
                                 className='btn-outline-light generic-navigation-button'
                                 to={resolveCalendarHome()}>
            {identity.isGeneric
                ? <DateRange className="mr-1"/>
                : <EventAvailable className="mr-1"/>}
            <span className='generic-navigation-text'>{text}</span>
        </WavesTooltipLink>
    };

    const resolveCalendarHome = () => {
        if(!identity.isSelf) {
            if(calendarVisibility.isNone) {
                return `/${username}/restricted?page=CALENDAR&visibility=NONE`;

            } else if (calendarVisibility.isFriends && !isFriend) {
                return `/${username}/restricted?page=CALENDAR&visibility=FRIENDS`;
            }
        }
        return `/${username}/calendar`;
    }

    const resolveFriendsHome = () => {
        if(!identity.isSelf) {
            if(friendsVisibility.isNone) {
                return `/${username}/restricted?page=FRIENDS&visibility=NONE`;

            } else if (friendsVisibility.isFriends && !isFriend) {
                return `/${username}/restricted?page=FRIENDS&visibility=FRIENDS`;
            }
        }
        if(isMobile) {
            return `/${username}/friends/mobile`;
        }
        return `/${username}/friends`
    }

    const renderFriendButtons = () => {
        const {space, isFriend, chatCount} = homedata;
        const firstname = space.user.firstname;

        const text = identity.isSelf ? Lang.generic.nav.friends.chat
            : identity.isOther ? Lang.generic.nav.friends.other(firstname)
                : Lang.generic.nav.friends.generic;

        return <React.Fragment>
            <WavesTooltipLink btn small
                              title={text}
                              className='btn-outline-light generic-navigation-button mr-2'
                              to={resolveFriendsHome()}>
                {chatCount > 0 && <span className="badge rounded-pill bg-danger mr-1">{chatCount}</span>}
                <ChatIcon className="mr-1"/>
                <span className='generic-navigation-text'>{text}</span>
            </WavesTooltipLink>

            {identity.isSelf && homedata.pending > 0 &&
            <WavesTooltipLink btn small
                              title={Lang.user.nav.pendingTooltip(firstname)}
                              className='btn-outline-light generic-navigation-button'
                              to={`/${username}/pending`}>
                <Schedule className="mr-1"/>{homedata.pending}
                <span className='generic-navigation-text'> {Lang.user.nav.pending}</span>
            </WavesTooltipLink>}

            {!identity.isSelf && !isFriend &&
            <WavesTooltipButton btn small
                                title={Lang.user.nav.addFriendTooltip(firstname)}
                                className='btn-outline-light generic-navigation-button'
                                onClick={handleAddFriend}>
                <PersonAdd className="mr-1"/>
                <span className='generic-navigation-text'>{Lang.user.nav.addFriend}</span>
            </WavesTooltipButton>}
        </React.Fragment>
    };

    return <div className='generic-navigation-buttons'>
        {renderCalendarButton()}
        {isAuthorized &&
        renderFriendButtons()}
    </div>
};

export default HomeUserNavigation;