import {CLUB_MEMBERSHIP, FREE_MEMBERSHIP, VIP_MEMBERSHIP} from "../../../actions/spaces";
import {ClubMemberIcon, StarsIcon, VipMemberIcon} from "./nav-icons";
import React from "react";
import {TooltipIcon} from "../buttons/nav-buttons";
import {useLanguage} from "../../configuration/configuration";


export const MembershipIconLong = ({membership}) => {
    const Lang = useLanguage();

    if (!membership || membership === FREE_MEMBERSHIP) return null;

    let icon;
    switch(membership) {
        case VIP_MEMBERSHIP:
            icon = <TooltipIcon title={Lang.access.membershipOnly.tooltip(membership)}><VipMemberIcon/></TooltipIcon>
            break;
        case CLUB_MEMBERSHIP:
            icon = <TooltipIcon title={Lang.access.membershipOnly.tooltip(membership)}><ClubMemberIcon/></TooltipIcon>
            break;
        default:
            icon = <TooltipIcon title={Lang.access.membershipOnly.tooltip(membership)}><StarsIcon/></TooltipIcon>
    }

    return <span className='text-danger'>
       {icon} {Lang.access.membershipOnly.text(membership)}
        </span>
}

export const MembershipIconShort = ({membership}) => {
    const Lang = useLanguage();

    if (!membership || membership === FREE_MEMBERSHIP) return null;
    let icon;
    switch(membership) {
        case VIP_MEMBERSHIP:
            icon = <TooltipIcon title={Lang.access.membershipOnly.tooltip(membership)}><VipMemberIcon small/></TooltipIcon>
            break;
        case CLUB_MEMBERSHIP:
            icon = <TooltipIcon title={Lang.access.membershipOnly.tooltip(membership)}><ClubMemberIcon small/></TooltipIcon>
            break;
        default:
            icon = <TooltipIcon title={Lang.access.membershipOnly.tooltip(membership)}><StarsIcon small/></TooltipIcon>
    }

    return <span className='ml-1 text-danger'>
            {icon}
        </span>
}

