import {checkIsServerAlive, isServerAlive, TOKEN_EXPIRED} from "../../actions/error-handling";
import axios from "axios";
import {getRenewAuthTokenUrl} from "../../actions/environment";
import {getBearer, refreshConfig, removeBearer, saveBearer} from "../../actions/local-storage";
import {useRef, useState} from "react";
import {ERROR_STATUS, NONE_STATUS, PENDING_STATUS, SUCCESS_STATUS} from "../../actions";
import {useHistory} from "react-router-dom";


/**
 * error handler for sync requests i.e. outside redux only. This handler replicates async error handling essentially
 * for recovering from jwt token expired errors. unhandled errors go to error page or display page not found
 *
 * the handler has not a setStatus of its own to allow the caller (another hook) to handle that by himself.
 * instead, it accepts a setter for signaling error or success
 *
 * @param setStatus  (update status of the caller)
 * @param retryCount (optional, defaults to one retry to avoid error loops)
 */
const useErrorHandler = (setStatus, retryCount = 1) => {
    const errorRef = useRef(null);
    const retryCountRef = useRef(retryCount);
    const history = useHistory();

    /**
     * @param error axios error
     * @param callback original call that produced the error and that has to be retried
     */
    const handleError = (error, callback) => {
        checkIsServerAlive(error);

        if(retryCountRef.current === 0) {
            setStatus(ERROR_STATUS);
            gotoPage('error-page', {status: 400,
                error: 'System error',
                message: 'Retry count expired. Server is not responding. Operation cancelled.'})
        }

        retryCountRef.current > 0 && retryCountRef.current--;
        if (isServerAlive() && error && error.response && error.response.data) {
            const {data} = error.response;

            if (data.errorCode === TOKEN_EXPIRED) {
                console.log('ERROR RECOVERY', retryCountRef.current);
                callback ? refreshToken(callback) : forceLogout();

            } else {
                setStatus(ERROR_STATUS);
                errorRef.current = data;

                if (data.status >= 300 && data.status <= 400) {
                    // none for the moment - let the caller handle these errors
                }
                else if (data.status === 401) {
                    gotoPage('error-page', data);
                } else if (data.status === 403) {
                        console.log('FORBIDDEN', retryCountRef.current)
                        // operation not authorized by server
                        gotoPage('error-page',{status: 403,
                            error: 'Forbidden',
                            message: 'A fatal system error occurred'});
                } else if (data.status === 404) {
                    gotoPage('page-not-found', data);
                } else {
                    gotoPage('error-page', data);
                }
            }
        } else {
            console.log('FORBIDDEN 01', retryCountRef.current)

            gotoPage('error-page', {status: 400,
                error: 'System error',
                message: 'A fatal system error occurred'})
        }
    }

    function refreshToken(callback) {
        setStatus(PENDING_STATUS);
        errorRef.current = null;
        axios.get(getRenewAuthTokenUrl(), refreshConfig())
            .then(response => {
                console.log('** SYNC TOKEN REFRESH OK', response);
                setStatus(SUCCESS_STATUS);
                saveBearer({...getBearer(), 'token': response.data.token});
                callback && callback();
            })
            .catch(error => {
                const jsonError = error.toJSON();
                console.log('** SYNC SECURITY ERROR', jsonError);
                setStatus(ERROR_STATUS);
                errorRef.current = jsonError;
                forceLogout();
            });
    }

    function forceLogout() {
        removeBearer();
        history.push('/');
    }

    function gotoPage(page, data) {
        history.push(`/${page}?status=${data.status}&error=${data.error}&message=${data.message}`);
    }

    return {handleError: handleError, forceLogout: forceLogout, error: errorRef.current}
}

export default useErrorHandler;