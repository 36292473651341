import React, {useState} from 'react';
import {BoxedLink, RoundedButton} from "../buttons/nav-buttons";
import {ImageBoxSmall} from "../../boxes/image-box-small";
import {UserBoxBig} from "../../boxes/user-box-big";
import {generateAvatar} from "../../billboard-cover/generate-avatar";
import {getImageServerThumbsUrl, getUserAvatar} from "../../../actions/environment";
import {NavigationGroup, NavigationRow} from "../layout/nav-layout";
import {ChevronDownSvg} from "../icons/nav-icons";
import {Wifi} from "@material-ui/icons";
import {formatFnsToNow} from "../../configuration/configuration";
import {cn} from "../../util/text-utils";


export const AnimatedChevronIcon2 = ({isOpen, onClick, ...otherProps}) => {
    return <RoundedButton className='rounded-button-small' onClick={onClick} {...otherProps}>
        <ChevronDownSvg fill='#0074D9'
                        className={`clr-blue headline-icon-rotate ${isOpen ? 'down' : ''}`}/>
    </RoundedButton>
}

export const HeadlineTitle2 = ({className, title, icon, open = false, ...otherProps}) => {
    const [isOpen, setIsOpen] = useState(open);

    const handleOnOpen = event => {
        setIsOpen(!isOpen);
    }

    return <NavigationRow className='headline-border'>
        <NavigationGroup>
            <div className={cn('headline-title centered-box', [className])}>
                {icon && <span>{icon}</span>}
                {title && title}
            </div>
        </NavigationGroup>

        <NavigationGroup {...otherProps}>
            <AnimatedChevronIcon2 onClick={handleOnOpen} isOpen={isOpen}/>
        </NavigationGroup>
    </NavigationRow>
}

export const SidebarHeadline = ({children, className, border = true, title, icon, ...otherProps}) => {
    return <NavigationRow className={cn([border, 'sidebar-border'], [className])}>
        <NavigationGroup>
            <span className='sidebar-headline-title'>{icon && icon} {title}</span>
        </NavigationGroup>
        <NavigationGroup {...otherProps}>
            {children}
        </NavigationGroup>
    </NavigationRow>
}

// attempts to construct a text avatar if image is null
export const ImageLink = ({className, to, image, text}) => {
    let imageProps = {image: image}
    if(!image) {
        const names = text ? text.split(' ', 2) : ['N', 'N'];
        imageProps = {...imageProps, avatar: generateAvatar(names[0], names[1] ? names[1] : 'N')}
    }

    return <BoxedLink className={cn([className])} to={to}>
        <ImageBoxSmall {...imageProps}/>
        <span className='share-post-text'>{text}</span>
    </BoxedLink>
}

export const UserBoxedLink = ({className, to, text, blocked, user, online, ...otherProps}) => {
    return <BoxedLink className={cn('user-boxed-link', [blocked, 'blocked-link'], [className])}
                      text={text} to={to}>
        <UserBoxBig user={user} blocked={blocked} {...otherProps}/>
        <div className='user-boxed-text my-1'>
            <div>{`${user.firstname} ${user.lastname}`}</div>
            {text && <span className='small text-muted mt-1'>{text}</span>}
            {online && <div className='box-aligned right-align small mt-1'>
                <Wifi fontSize='small' className='clr-green mr-1'/>
                <span className='clr-white'>Online {formatFnsToNow(online.connectedAt)}</span>
            </div>}
        </div>
    </BoxedLink>
}

// same as UserBoxLink but reacts to click instead of going to page thru Link
export const UserBoxedAnchor = ({className, onClick, text, blocked, user, ...otherProps}) => {
    const resolvedClass = cn('box-aligned cursor-pointer', [blocked, 'blocked-link'], [className])
    return <a className={resolvedClass} onClick={onClick}>
        <UserBoxBig user={user} blocked={blocked} {...otherProps}/>
        <div className='user-boxed-text'>
            <div className={cn([blocked, 'clr-gray'])}>
                {`${user.firstname} ${user.lastname}`}
            </div>
            {text && <span className='small mt-1'>{text}</span>}
        </div>
    </a>
}

// helper component for usage mainly within a html tooltip - deprecated
export const AvatarBoxedLink = ({className, to, user}) => {
    const avatar = user.avatar
        ? getImageServerThumbsUrl(user.avatar)
        : generateAvatar(user.firstname, user.lastname);

    return <BoxedLink className={cn([className])} to={to}>
        <img className='avatar-link-img' src={avatar}/>
        <span className='avatar-link-text'>{`${user.firstname} ${user.lastname}`}</span>
    </BoxedLink>
}

// native-markup only for usage within a html tooltip
export const AvatarFlatAnchor = ({href, user, ...otherProps}) => {
    const avatar = getUserAvatar(user);

    return <a href={href} {...otherProps}>
        <img className='avatar-link-img' src={avatar}/>
        <span className='avatar-link-text'>{`${user.firstname} ${user.lastname}`}</span>
    </a>
}




