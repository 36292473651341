import {asyncUpdateUserStatus, NONE_STATUS, PENDING_STATUS, SUCCESS_STATUS} from "../../actions";
import {notify} from "../notifications/notify";
import {useState} from "react";
import {useDispatch} from "react-redux";
import {useLanguage} from "../configuration/configuration";
import {useAuthorization} from "../../selectors";

const useAsyncUpdateStatus = () => {
    const [status, setStatus] = useState(NONE_STATUS);
    const [userdata, setUserdata] = useState(null);
    const {principalUsername} = useAuthorization();
    const Lang = useLanguage();
    const dispatch = useDispatch();

    const updateStatus = dailyStatus => {
        setStatus(PENDING_STATUS);
        dispatch(asyncUpdateUserStatus(principalUsername, {status: dailyStatus}, userdata => {
                setStatus(SUCCESS_STATUS);
                setUserdata(userdata);
                notify(Lang.dialog.updateStatus.messageDone);
            })
        );
    }

    return {updateStatus: updateStatus, userdata: userdata, status}
}
export default useAsyncUpdateStatus;