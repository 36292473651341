import React from "react";
import {MarkDownText} from "./markdown-text-toggler";
import {useLanguage} from "../configuration/configuration";

const MarkdownTextarea = ({onToggle, value, ...otherProps}) => {
    const Lang = useLanguage();

    return onToggle
        ? value ? <MarkDownText text={value}/> : <span>{Lang.markdown.toggle.noneFound}</span>
        : <textarea value={value} {...otherProps}/>
}

export default MarkdownTextarea;

