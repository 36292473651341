import {Component} from "react";

/**
 *  Example "componentStack":
 *      in ComponentThatThrows (created by App)
 *      in ErrorBoundary (created by App)
 *      in div (created by App)
 *      in App
 */
export class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    // Update state so the next render will show the fallback UI.
    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    // optionally send error log to server
    componentDidCatch(error, info) {
        console.log(error, info.componentStack);
    }

    render() {
        if (this.state.hasError) {
            const {fallback} = this.props;
            return fallback ? fallback() : <p>Something went wrong</p>;
        }
        return this.props.children;
    }
}