// concentrate external dependencies in one single point

import toastr from "toastr";
toastr.options.closeButton = true;
toastr.options.preventDuplicates = true;
toastr.options.timeOut = 5000;
toastr.options.extendedTimeOut = 5000;

export const notify = text => {
    toastr.options.positionClass = 'toast-top-right';
    toastr.info(text);
}

export const notifyError = text => {
    toastr.options.positionClass = 'toast-bottom-right';
    toastr.error(text);
}

export const notifySuccess = text => {
    toastr.options.positionClass = 'toast-top-right';
    toastr.success(text);
}

export const notifyWarning = text => {
    toastr.options.positionClass = 'toast-top-right';
    toastr.warning(text);
}
