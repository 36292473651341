import {NONE_STATUS, NOTIFY_PAGE_CHANGE, NOTIFY_TOGGLE_COMMENTS, NOTIFY_UPLOAD_CHANGE} from "../../actions";

const initialState = {
    upload: {action: 'UPLOAD', status: NONE_STATUS, payload: null},
    comments: {action: 'TOGGLE', toggle: false, postId: null},
}

// action.data expected something like {action: 'fetch', status: 'success', payload: page}
const NotificationReducer = (state=initialState , action) => {
    switch (action.type)  {
        case NOTIFY_PAGE_CHANGE:
            return {...state, page: action.data}

        case NOTIFY_UPLOAD_CHANGE:
            return {...state, upload: action.data}

        case NOTIFY_TOGGLE_COMMENTS:
            return {...state, comments: action.data}

        default:
            return state;
    }
}

export default NotificationReducer;