import {useState} from "react";
import axios from "axios";
import {authConfig} from "../../actions/local-storage";
import {getFetchAccountQrCodeUrl, getFetchMemberQrCodeUrl} from "../../actions/environment";
import useErrorHandler from "./use-error-handler";
import {NONE_STATUS, PENDING_STATUS, SUCCESS_STATUS} from "../../actions";


// authorized users only
const useFetchQRCode = () => {
    const [status, setStatus] = useState(NONE_STATUS);
    const [qrCode, setQrCode] = useState(null);
    const {handleError, error} = useErrorHandler(setStatus);

    const fetchMemberQrCode = (username, spaceId) => {
        setStatus(PENDING_STATUS);
        axios.get(getFetchMemberQrCodeUrl(username, spaceId), authConfig())
            .then(response => {
                setQrCode(response.data);
                setStatus(SUCCESS_STATUS);
            })
            .catch(error => {
                handleError(error, () => fetchMemberQrCode(username, spaceId));
            })
    }

    const fetchAccountQrCode = (username) => {
        setStatus(PENDING_STATUS);
        axios.get(getFetchAccountQrCodeUrl(username), authConfig())
        .then(response => {
            setQrCode(response.data);
            setStatus(SUCCESS_STATUS);
        })
        .catch(error => {
            handleError(error, () => fetchAccountQrCode(username));
        })
    }

    return {fetchMemberQrCode: fetchMemberQrCode,
        fetchAccountQrCode: fetchAccountQrCode,
        qrCode: qrCode,
        status: status,
        error: error}
}

export default useFetchQRCode;