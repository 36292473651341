import {useState} from "react";
import useErrorHandler from "./use-error-handler";
import axios from "axios";
import {
    getDeleteSpaceMediaUrl,
    getHomeSpaceMediaUrl,
    getPeekGallerySpacesBySpaceUrl,
    getPeekGallerySpacesUrl,
    getPeekPublicGallerySpacesUrl,
    getPublicHomeSpaceMediaUrl,
    getPublicSpaceMediaUrl,
    getSpaceMediaUrl
} from "../../actions/environment";
import {authConfig} from "../../actions/local-storage";
import {ACTIVE_PEEK_MODE, PENDING_STATUS, SUCCESS_STATUS} from "../../actions";
import {useAuthorization, useGenericSpaces} from "../../selectors";

const useFetchGallery = () => {
    const [status, setStatus] = useState('none');
    const [spaces, setSpaces] = useState([]);
    const [spaceMedia, setSpaceMedia] = useState(null);
    const {isAuthorized} = useAuthorization();
    const {media: postMedia, member} = useGenericSpaces();
    const {handleError, error} = useErrorHandler(setStatus);

    // fetch gallery peek spaces ie any active gallery space for that user
    // refactor to fetchGalleriesByUser
    const fetchGallerySpaces = (username, callback) => {
        console.log('Fetch gallery spaces');
        const url = isAuthorized ? getPeekGallerySpacesUrl(username, ACTIVE_PEEK_MODE)
            : getPeekPublicGallerySpacesUrl(username, ACTIVE_PEEK_MODE)

        setStatus(PENDING_STATUS);
        axios.get(url, authConfig())
            .then(response => {
                setSpaces(response.data);
                callback && callback(response.data);
                setStatus(SUCCESS_STATUS);
            })
            .catch(error => {
                handleError(error, () => fetchGallerySpaces(username, callback));
            })
    }

    // authorized users only - fetches galleries specific to a group space
    const fetchGalleriesBySpace = (username, spaceId, callback) => {
        console.log('Fetch gallery spaces by space');
        const url = getPeekGallerySpacesBySpaceUrl(username, spaceId);

        setStatus(PENDING_STATUS);
        axios.get(url, authConfig())
        .then(response => {
            setSpaces(response.data);
            callback && callback(response.data);
            setStatus(SUCCESS_STATUS);
        })
        .catch(error => {
            handleError(error, () => fetchGalleriesBySpace(username, spaceId, callback));
        })
    }

    // media is of type media and corresponds to all images in the home space postings of the user
    const fetchHomeSpaceMedia = (username, spaceId, callback) => {
        console.log('Fetch home gallery media', username);
        const url = isAuthorized ? getHomeSpaceMediaUrl(username) : getPublicHomeSpaceMediaUrl(username)

        setStatus(PENDING_STATUS);
        axios.get(url, authConfig())
        .then(response => {
            setSpaceMedia(response.data);
            callback && callback(response.data);
            setStatus(SUCCESS_STATUS);
        })
        .catch(error => {
            handleError(error, () => fetchSpaceMedia(username, spaceId, callback));
        })
    }

    // media is of type space media usually a custom user gallery
    const fetchSpaceMedia = (username, spaceId, callback) => {
        console.log('Fetch gallery media');
        const url = isAuthorized ? getSpaceMediaUrl(username, spaceId) : getPublicSpaceMediaUrl(username, spaceId)

        setStatus(PENDING_STATUS);
        axios.get(url, authConfig())
            .then(response => {
                setSpaceMedia(response.data);
                callback && callback(response.data);
                setStatus(SUCCESS_STATUS);
            })
            .catch(error => {
                handleError(error, () => fetchSpaceMedia(username, spaceId, callback));
            })
    }

    const filterSpaceMediaById = id => {
        const filtered = spaceMedia.media.filter(media => media.id !== id);
        setSpaceMedia({...spaceMedia, media: filtered})
    }

    const deleteSpaceMedia = (username, mediaId, callback) => {
        console.log('Delete gallery media', mediaId);

        setStatus(PENDING_STATUS);
        axios.delete(getDeleteSpaceMediaUrl(username, mediaId), authConfig())
            .then(response => {
                filterSpaceMediaById(response.data.id);
                // setSpaceMedia(response.data);
                callback && callback(response.data);
                setStatus(SUCCESS_STATUS);
            })
            .catch(error => {
                handleError(error, () => deleteSpaceMedia(username, mediaId, callback));
            })
    }


    return {
        // refactor to fetchGalleriesByUser
        fetchGallerySpaces: fetchGallerySpaces,
        fetchGalleriesBySpace: fetchGalleriesBySpace,
        fetchHomeSpaceMedia: fetchHomeSpaceMedia,
        fetchSpaceMedia: fetchSpaceMedia,
        deleteSpaceMedia: deleteSpaceMedia,
        spaces: spaces, postMedia: postMedia, spaceMedia: spaceMedia, status: status, error: error
    }
}

export default useFetchGallery;