import React, {useEffect} from "react";
import holderjs from "holderjs";


const CoverHolder = ({text}) => {

    useEffect(() => {
        holderjs.run();
    },[text]);

    const holder = `holder.js/100px360?auto=yes&theme=social&text=${text}&size=24&outline=yes`;
    return <div className='fit-cover'>
        <img src={holder} data-ignore={true}/>
    </div>
}

export const ImageHolder = ({text, rectangle='120x64', font='8'}) => {

    useEffect(() => {
        holderjs.run();
    },[text, rectangle, font]);

    const holder = `holder.js/${rectangle}?auto=yes&theme=social&text=${text}&size=${font}&outline=yes`;
    return <div className='fit-cover'>
        <img src={holder} data-ignore={true}/>
    </div>
}

export default CoverHolder;