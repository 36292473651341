import React from "react";
import {cn} from "../../util/text-utils";

export const NavigationRow = ({children, className, dark, right, left, ...otherProps}) => {
    const computed = cn('navigation-row',
        [dark, 'navigation-row-dark'],
        [left, 'navigation-row-left'],
        [right, 'navigation-row-right'],
        [className]);

    return <div className={computed} {...otherProps}>
        {children}
    </div>
};

export const NavigationGroup = ({children, className, dark, column, ...otherProps}) => {
    const computed = cn('navigation-group',
        [dark, 'navigation-group-dark'],
        [className],
        [column, 'navigation-group-column']);

    return <div className={computed} {...otherProps}>
        {children}
    </div>
};