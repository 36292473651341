import {useEffect, useRef} from "react";
import {
    asyncFetchMemberOfSpaces,
    asyncFetchParentSpaces,
    CONTEXT_VIEW_SPACE,
    EVENT_SPACE,
    GENERIC_SPACE
} from "../../actions/spaces";
import {getPublicUser} from "../../actions/environment";
import {useAuthorization, useSiteConfiguration, useViewSpaces} from "../../selectors";
import {useDispatch} from "react-redux";
import {PENDING_STATUS, SUCCESS_STATUS} from "../../actions";

export const VIEW_TYPE_ALL = 'all';
export const VIEW_TYPE_EVENTS = 'events';
export const VIEW_TYPE_SPACES = 'spaces';


/// ***************** @Deprecated
// similar to async-fetch-generic-spaces but assigning to a different reducer thru the context view
const useAsyncFetchViewSpaces = (username, location, type = VIEW_TYPE_ALL) => {
    const status = useRef({spaces: SUCCESS_STATUS, events: SUCCESS_STATUS});
    const {isAuthorized, isSuperUser} = useAuthorization();
    const {viewSpaces, viewEvents} = useViewSpaces();

    const {calendarPage, siteUsername} = useSiteConfiguration();
    const dispatch = useDispatch();

    const fetchViewSpaces = fetchName => {
        status.current = {...status.current, spaces: PENDING_STATUS};
        if (fetchName.isParent) {
            dispatch(asyncFetchParentSpaces(fetchName.name, GENERIC_SPACE, CONTEXT_VIEW_SPACE, data => {
                status.current = {...status.current, spaces: SUCCESS_STATUS};
            }));
        } else {
            dispatch(asyncFetchMemberOfSpaces(fetchName.name, GENERIC_SPACE, CONTEXT_VIEW_SPACE, data => {
                status.current = {...status.current, spaces: SUCCESS_STATUS};
            }));
        }
    };

    const fetchViewEvents = fetchName => {
        status.current = {...status.current, events: PENDING_STATUS};
        dispatch(asyncFetchParentSpaces(fetchName.name, EVENT_SPACE, CONTEXT_VIEW_SPACE, data => {
            status.current = {...status.current, events: SUCCESS_STATUS};
        }));
    };

    const resolveNameByLocation = () => {
        // check for username not null as a special case for slideout navigation and force a public user query
        // also see main index, slideout is not part of the router component
        if (isAuthorized && username) {
            return calendarPage === location.pathname
                ? {name: siteUsername, isParent: true}
                : {name: username, isParent: !!isSuperUser};
        }
        return {name: getPublicUser(), isParent: true}
    }

    useEffect(() => {
        const fetchName = resolveNameByLocation();

        (type === VIEW_TYPE_ALL || type === VIEW_TYPE_SPACES) && fetchViewSpaces(fetchName);
        (type === VIEW_TYPE_ALL || type === VIEW_TYPE_EVENTS) && fetchViewEvents(fetchName);

    }, [username, location]);

    return {viewSpaces: viewSpaces, viewEvents: viewEvents, status: status.current}
}

export default useAsyncFetchViewSpaces;