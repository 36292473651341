import {useAuthorization} from "../../selectors";
import {useState} from "react";
import {ERROR_STATUS, NONE_STATUS, PENDING_STATUS, SUCCESS_STATUS} from "../../actions";
import axios from "axios";
import {authConfig} from "../../actions/local-storage";
import {getGlobalSearchUrl, getUserSearchUrl} from "../../actions/environment";
import useErrorHandler from "./use-error-handler";


export const GLOBAL_SEARCH = 'global';      // users and spaces
export const USER_SEARCH = 'user';          // users only
export const GENERIC_SEARCH = 'generic';    // spaces or events only

const SEARCH_SIZE = 10;

const useGlobalSearch = () => {
    const [status, setStatus] = useState(NONE_STATUS);
    const [result, setResult] = useState([]);
    const {principalUsername} = useAuthorization();
    const {handleError, error} = useErrorHandler(setStatus);

    const validateSearchTerm = searchTerm => {
        if (!searchTerm || searchTerm.length < 2) {
            setStatus(SUCCESS_STATUS);
            setResult([])
            return false;
        }
        return true;
    }

    const globalSearch = (searchTerm, type = GLOBAL_SEARCH) => {
        if (!validateSearchTerm(searchTerm)) return;

        setStatus(PENDING_STATUS)
        axios.get(getGlobalSearchUrl(principalUsername, searchTerm, SEARCH_SIZE), authConfig())
            .then(response => {
                setResult(response.data);
                setStatus(SUCCESS_STATUS)
            })
            .catch(error => {
                setResult([])
                handleError(error, () => globalSearch(searchTerm, type));
            })
    }

    const userSearch = (searchTerm, type = USER_SEARCH) => {
        if (!validateSearchTerm(searchTerm)) return;

        setStatus(PENDING_STATUS)
        axios.get(getUserSearchUrl(principalUsername, searchTerm, SEARCH_SIZE), authConfig())
            .then(response => {
                setResult(response.data);
                setStatus(SUCCESS_STATUS)
            })
            .catch(error => {
                setResult([])
                handleError(error, () => userSearch(searchTerm, type));
            })
    }



    return {globalSearch: globalSearch, userSearch: userSearch, result: result, status:status, error: error}
}
export default useGlobalSearch;