import {RoundedButton} from "../../navigation/buttons/nav-buttons";
import React from "react";
import {asyncValidateAuth, PENDING_STATUS, SUCCESS_STATUS} from "../../../actions";
import {useDispatch} from "react-redux";
import {useLanguage} from "../../configuration/configuration";
import {PhotoCamera} from "@material-ui/icons";
import {useAuthorization} from "../../../selectors";
import {notify, notifyError} from "../../notifications/notify";
import {Spinner} from "../../util/spinner";
import useUploadAvatar from "../../hooks/use-upload-avatar";

// unfortunately there is no reliable method to detect cancel on file system dialog
const BillboardAvatarUpload = ({username, homedata}) => {
    const {isSuperUser, principalUsername} = useAuthorization();
    const {uploadAvatar, status} = useUploadAvatar();
    const Lang = useLanguage();
    const dispatch = useDispatch();

    const handleValidateAuth = (event) => {
        // this essentially resets the jwt authorization token
        dispatch(asyncValidateAuth(username));
    }

    const handleUploadAvatar = (event) => {
        event.preventDefault();
        const filelist = event.target.files;
        if (filelist.length !== 1) {
            notifyError(Lang.avatar.upload.oneFileOnly);
            return;
        }
        const formData = new FormData();
        formData.append("file", filelist.item(0));

        // this is basically the superuser uploading an avatar for somebody else
        const isSurrogate = isSuperUser && !homedata.isOwner;
        const resolvedUsername = isSurrogate ? homedata.space.user.username : principalUsername;

        uploadAvatar(resolvedUsername, isSurrogate, formData);
    }

    if (status === PENDING_STATUS) return <Spinner/>
    if (status === SUCCESS_STATUS) notify(Lang.avatar.upload.success)

    // it must be label for firing event
    return <label htmlFor="avatarUploadId">
        <input type="file" id="avatarUploadId"
               onClick={handleValidateAuth}
               onChange={handleUploadAvatar}/>
        <RoundedButton div title={Lang.generic.nav.uploadAvatar}
                       className='btn-primary rounded-button-small billboard-avatar-icon'>
            <PhotoCamera fontSize='small' className="clr-white"/>
        </RoundedButton>
    </label>

}

export default BillboardAvatarUpload;