import React, {useEffect, useState} from "react";
import {getImageServerFullUrl, getPublicUser} from "../../actions/environment";
import UserLink from "../public/user-link";
import PostComments from "../comment/post-comments";
import LightboxGallery from "../billboard-headlines/lightbox-gallery";
import {asyncDeleteMedia, PENDING_STATUS} from "../../actions";
import {localDeleteMedia, STATE_SHARED} from "../../actions/spaces";
import {useDispatch, useSelector} from "react-redux";
import {allowComments as checkAllowComments, useAuthorization, useIsHomeSpaceLocation} from "../../selectors";
import {useIsMobile, useLanguage} from "../configuration/configuration";
import {showVisibleImages} from "../../actions/image-handler";
import TimelineMedia from "./timeline-media";
import {notify} from "../notifications/notify";
import PostText from "../post/post-text";
import PostNavigation from "../post/post-navigation";
import SpaceAccessIcon from "../navigation/icons/space-access-icon";
import {NavigationGroup, NavigationRow} from "../navigation/layout/nav-layout";
import {Tooltip} from "../navigation/buttons/nav-buttons";
import {Popover} from "../navigation/popover/popover";
import PostVisibilityEdit from "../popover/post-visibility-edit";
import useUpdatePostVisibility from "../hooks/use-update-post-visibility";
import GenericPostVisibilityEdit from "../popover/generic-post-visibility-edit";
import SpaceLink from "../public/space-link";

const MAX_IMAGES = 4;

const TimelinePost = ({post, username, spacename, configuration}) => {
    const [access, setAccess] = useState(post.access);
    const [isEditable, setIsEditable] = useState(false);
    const [showMediaGallery, setShowMediaGallery] = useState({isOpen: false, index: 0});
    const allowComments = useSelector(state => checkAllowComments(state));
    const {authorization, isAuthorized, isSuperUser, principalUsername} = useAuthorization();
    const {updatePostVisibility, post: updatePost, status} = useUpdatePostVisibility();
    const {isHomeSpace} = useIsHomeSpaceLocation();
    const Lang = useLanguage();
    const isMobile = useIsMobile();
    const dispatch = useDispatch();

    useEffect(() => {
        isAuthorized && setIsEditable((principalUsername === post.user.username) || isSuperUser);
    }, [post])

    const handleShowMediaGallery = event => {
        event.preventDefault();
        const index = event.currentTarget.dataset.index;
        setShowMediaGallery({isOpen: true, index: index})
    }

    const deleteMedia = mediaId => {
        dispatch(asyncDeleteMedia(principalUsername, post.id, mediaId, post => {
                dispatch(localDeleteMedia([{id: mediaId}]));
                showVisibleImages();
                notify(Lang.button.deleteImage.confirm(post.id));
            })
        );
    };

    const updateVisibility = access => {
        isAuthorized && updatePostVisibility(post.id, {access: access}, (post) => {
            setAccess(access);
            showVisibleImages();
        })
    }

    const renderGalleryMediaBoxes = images => {
        return images.slice(0, MAX_IMAGES).map((image, idx) => {
            const showMoreMedia = images.length > MAX_IMAGES && idx === MAX_IMAGES - 1
                ? images.length - MAX_IMAGES : 0;

            return <TimelineMedia key={idx} media={image} index={idx + 1}
                                  showMoreMedia={showMoreMedia}
                                  isEditable={isEditable}
                                  onDelete={deleteMedia}
                                  onClick={handleShowMediaGallery}/>
        })
    }

    const renderGalleryMedia = images => {

        if (images.length === 1) {
            return <div className='timeline-gallery'>
                <TimelineMedia first media={images[0]}
                               onDelete={deleteMedia}
                               isEditable={isEditable}
                               onClick={handleShowMediaGallery}/>
            </div>

        } else if (images.length === 2) {
            return <div className='timeline-gallery'>
                <div className='timeline-gallery-row'>
                    <TimelineMedia twin media={images[0]}
                                   isEditable={isEditable}
                                   onDelete={deleteMedia}
                                   onClick={handleShowMediaGallery}/>

                    <TimelineMedia twin media={images[1]}
                                   index={1}
                                   isEditable={isEditable}
                                   onDelete={deleteMedia}
                                   onClick={handleShowMediaGallery}/>
                </div>
            </div>

        } else if (images.length > 2) {
            return <div className='timeline-gallery'>
                <TimelineMedia first media={images[0]}
                               isEditable={isEditable}
                               onDelete={deleteMedia}
                               onClick={handleShowMediaGallery}/>
                <div className='timeline-gallery-row'>
                    {renderGalleryMediaBoxes(images.slice(1))}
                </div>
            </div>;
        }

        return null;
    }

    const getLightBoxImages = () => {
        return post.media
            // .filter(media => media.type === 'PICTURE')
            .map(media => getImageServerFullUrl(media.url, isMobile));
    }

    const handleVisibility = ({close}) =>{
        const popover = isHomeSpace ?
            <PostVisibilityEdit postId={null} access={access} onUpdate={updateVisibility} onClose={close}/> :
            <GenericPostVisibilityEdit postId={null} access={access} onUpdate={updateVisibility} onClose={close}/>;

        return <div>{popover}</div>
    }

    // is this required?
    const hideFooter = !allowComments && post.user.username === getPublicUser();

    const isVisibilityEditable = isAuthorized &&
        ((post.from && post.from.username === principalUsername) ||
        (post.user.username === principalUsername));

    const isSharedPost = post.state === STATE_SHARED && post.fromSpace !== null;

    if(status === PENDING_STATUS) return null;

    // TODO access not working right

    return <div key={post.id} className="card post-card shadow-sm">
        <NavigationRow className='card-header'>
            <NavigationGroup>
                {!isSharedPost && <UserLink post={post} allowComments={allowComments}/>}
                {isSharedPost && <SpaceLink post={post} allowComments={allowComments}/>}
            </NavigationGroup>

            {isVisibilityEditable && <NavigationGroup>
                <Tooltip className=' btn btn-overrides'>
                    <Popover className='popover-visibility-container'
                             placement='bottom'
                             render={handleVisibility}>
                        <button className='btn rounded-button-regular rounded-button btn-outline-light'>
                            <SpaceAccessIcon access={access}/>
                        </button>
                    </Popover>
                </Tooltip>
            </NavigationGroup>}

            {!isVisibilityEditable && <NavigationGroup className='mr-3'>
                <SpaceAccessIcon access={access}/>
            </NavigationGroup>}
        </NavigationRow>

        {post.title && <h4 className="card-title">{post.title.toUpperCase()}</h4>}
        {post.text.length > 0 && <div className='post-content'>
            <PostText post={post} allowComments={allowComments} authorization={authorization}/>
        </div>}

        {renderGalleryMedia(post.media)}

        <div className="mx-2 mb-2">
            {post.title && <h4 className="card-title">{post.title.toUpperCase()}</h4>}
            <div className="card-content">
                <PostNavigation postId={post.id}
                                post={post} username={username}
                                spacename={spacename}
                                configuration={configuration}/>
            </div>
            {isAuthorized && allowComments &&
                <PostComments username={username} post={post}/>}
        </div>

        {showMediaGallery.isOpen &&
            <LightboxGallery media={getLightBoxImages()}
                             open={true}
                             index={showMediaGallery.index}
                             onClose={() => setShowMediaGallery({isOpen: false, index: 0})}/>}
    </div>
}

export default TimelinePost;