import Sortable from "sortablejs/Sortable";
import React, {useEffect, useRef} from "react";
import {useAuthorization} from "../../selectors";

// position - initial ranking
// data - reference to the data being sorted, usually an id
// list - <li> or <div>
export const SortableEntry = ({children, position, data, className, list = false, ...otherProps}) => {
    const Tag = (props) => React.createElement(list ? 'li' : 'div', {...props});
    return <Tag className={className ? className : ''}
                data-position={position}
                data-space={data}
                {...otherProps}>
        {children}
    </Tag>
}

/**
 * only superuser or admin allowed for space reordering
 * (destroy triggers an exception on the library)
 * @param children
 * @param onSorted
 * @param onAdded
 * @param onRemoved
 * @param list -  <ul> or <div>
 * @param group
 * @param otherProps
 */
const SortableContainer = ({
                               children, onSorted, onAdded = null, onRemoved = null,
                               list = false, group = null, ...otherProps
                           }) => {
    const sortableMenuRef = useRef(null);
    const sortableRef = useRef(null);
    const {isAuthorized, isSuperUser, isAdmin} = useAuthorization();

    useEffect(() => {
        if (sortableMenuRef.current && !sortableRef.current
            && isAuthorized && (isSuperUser || isAdmin)) {
            let params = {
                animation: 150, onEnd: onSorted,

            };
            if (onAdded) params = {...params, onAdd: onAdded};
            if (onRemoved) params = {...params, onRemove: onRemoved};
            if (group) params = {
                ...params,
                group: group,
                store: {
                    get: function (sortable) {
                        let order = localStorage.getItem(sortable.options.group.name);
                        return order ? order.split('|') : [];
                    },
                    set: function (sortable) {
                        let order = sortable.toArray();
                        localStorage.setItem(sortable.options.group, order.join('|'));
                    }
                }
            };

            sortableRef.current = Sortable.create(sortableMenuRef.current, params);
        }
        // return () => sortableRef.current && sortableRef.current.destroy();
    }, []);

    return list ?
        <ul ref={sortableMenuRef} {...otherProps}>
            {children}
        </ul>
        : <div ref={sortableMenuRef} {...otherProps}>
            {children}
        </div>
}

export default SortableContainer;