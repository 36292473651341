import 'simplebar';
import 'simplebar/dist/simplebar.css';
import React from "react";

// simple scrollbar component as replacement for overlay scrollbars
// TODO needs to support onScrollStop
const SimpleBarScroller = ({children, classPath, ...otherProps}) => {

    return <div className={`${classPath ? classPath : ''}`}  {...otherProps} data-simplebar>
        {children}
    </div>
}

export default SimpleBarScroller;