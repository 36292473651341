import React from "react";

const shortName = 'Your Social Media Platform';
const noInternet = 'No Internet';
const retry = 'Retry';
const notAlive = 'Server not responding'
const pageNotFound = 'Page not found'


const forceRefresh = () => {
    window.location = '/';
}

// fallback page when internet connection fails and browser has cached data available
export const NoInternetConnection = () => {

    const renderDetails = (details) => {
        const list = details.map((detail, index) => {
            return <li key={index}>{detail}</li>
        })
        return <ul className='message-small'>{list}</ul>
    }

    return <div className='navigation fixed-header'>
        <nav className="navbar navbar-expand-lg" style={{backgroundColor: '#033E6C'}}>
            <a className='navbar-brand' href='/'>
                <div className="logo-text-principal">{shortName}</div>
            </a>
        </nav>

        <div className='page-not-found-container error-page-container-bg'>
            <div className='page-not-found-body'>
                <div className='teaser'>404</div>
                <div className='message'>{pageNotFound}
                    <div className='message-small  text-bolder mb-4'>{noInternet}</div>
                    {renderDetails(noInternet)}
                </div>
            </div>
            <button className='btn btn-primary' onClick={forceRefresh}>{retry}</button>
        </div>
    </div>
};

export const ServerNotAlive = () => {

    const renderDetails = (details) => {
        const list = details.map((detail, index) => {
            return <li key={index}>{detail}</li>
        })
        return <ul className='message-small'>{list}</ul>
    }

    return <div className='navigation fixed-header'>
        <div className='page-not-found-container error-page-container-bg'>
            <div className='page-not-found-body'>
                <div className='teaser'>503</div>
                <div className='message'>{pageNotFound}
                    <div className='message-small  text-bolder mb-4'>{notAlive}</div>
                    {renderDetails(notAlive)}
                </div>
            </div>
            <button className='btn btn-primary' onClick={forceRefresh}>{retry}</button>
        </div>
    </div>
};