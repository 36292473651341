import React, {useEffect, useRef, useState} from "react";
import DOMPurify from "dompurify/dist/purify";
import {marked} from "marked";
import {HeadlineTitle2} from "../navigation/links/nav-headlines";
import {cn} from "../util/text-utils";
import SimpleBarScroller from "../scrollbars/simple-bar-scroller";
import {NONE_STATUS, PENDING_STATUS, SUCCESS_STATUS} from "../../actions";
import {Spinner} from "../util/spinner";

export const MarkDownText = ({text, ...otherProps}) => {
    const [status, setStatus] = useState(NONE_STATUS);
    const markdownRef = useRef(null);
    useEffect(() => {
        if (text && markdownRef.current) {
            setStatus(PENDING_STATUS);
            markdownRef.current.innerHTML = DOMPurify.sanitize(
                marked(text),
                {USE_PROFILES: {html: true}}
            );
            setStatus(SUCCESS_STATUS);
        }
    }, []);

    if (status === PENDING_STATUS) return <Spinner/>
    return <div {...otherProps} ref={markdownRef}/>
}

export const MarkDownTextToggler = ({title, text, icon, active, visible = false, className, ...otherProps}) => {
    const refElem = useRef(null);
    const [isVisible, setIsVisible] = useState(visible);

    const toggle = (event) => {
        event.preventDefault();
        const isVisible = refElem.current.classList.toggle('active-show');
        setIsVisible(isVisible);
    };

    if (!text) return '';

    return <div className="headline-entry">
        <HeadlineTitle2 title={title} onClick={toggle} icon={icon} open={visible} {...otherProps} />
        <div className={cn('headline-text-toggle', [active, 'active-show'], [className])}
             ref={refElem}>
            {isVisible && text && <SimpleBarScroller className='headline-user-toggler mr-2'>
                <MarkDownText className='pr-2' text={text}/>
            </SimpleBarScroller>}
        </div>
    </div>;
}

export default MarkDownTextToggler